import React, { useState } from "react";
import PAGE_COMPONENT_ENUMS from "../ENUMS/PAGE_COMPONENT_ENUMS";
import Loader from "../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../globalElements/Loader/LOADER_TYPE";

const PageComponent = ({ page, setPage, children, data, setVariantID, orderID, type, sendAction, sendLoader, finished, showContent }) => {
  const [omitted, setOmitted] = useState(0);

  const handleChoseVariantID = (id) => {
    if (setVariantID) setVariantID(id);
  };

  return (
    <div
      className={
        [PAGE_COMPONENT_ENUMS.PAGE_COMPONENT_TYPES.TEAM_GRAPHIC, PAGE_COMPONENT_ENUMS.PAGE_COMPONENT_TYPES.TEAM_ACCM].includes(type)
          ? "page_component_wrapper extended"
          : "page_component_wrapper"
      }
    >
      {showContent && <div className="bookmark-wrapper">
        {/*<div className="text-section">*/}
        {/*/!* EXTENDED PART OF COMPONENT ONLY FOR TEAM KeyVisual TITLE *!/*/}
        {/*  {[PAGE_COMPONENT_ENUMS.PAGE_COMPONENT_TYPES.TEAM_GRAPHIC].includes(type) && <h2 className="section-title">Key Visual</h2>}*/}
        {/*  <h2 className="version">WARIANT</h2>*/}
        {/*</div>*/}
        {showContent ? (
          <>

              <div className='variants-container'>
                  {omitted > 0 && (
                      <div className={`page_bookmark next_button`} onClick={() => setOmitted(Math.max(0, omitted - 1))}>
                          Poprzednie
                      </div>
                  )}
            {data.map((element, index) => {
              if (index >= omitted * 6 && index < (omitted + 1) * 6)
                return (
                  <div
                    key={index}
                    className={`page_bookmark ${page === index ? "selected_page" : ''} ${element?.has_changes ? "warning" : ''} ${element?.has_new_correction_flag ? "has-correction" : ""}`}
                    onClick={() => {
                      setPage(index);
                      handleChoseVariantID(element.id);
                    }}
                  >
                    {page !== index && `Wariant ${index + 1}`}

                    {page === index && (
                        `Wariant ${index + 1}`
                    )}
                  </div>
                );
            })}
                  {data.length > (omitted + 1) * 6 && (
                      <div className={`page_bookmark next_button`} onClick={() => setOmitted(omitted + 1)}>
                          Następne
                      </div>
                  )}
              </div>

            {/* EXTENDED PART OF COMPONENT ONLY FOR TEAM KeyVisual ACTIONS */}
            {[PAGE_COMPONENT_ENUMS.PAGE_COMPONENT_TYPES.TEAM_GRAPHIC].includes(type) && !finished && (
              <button className="send" onClick={sendAction}>
                {sendLoader ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} /> : `Wyślij projekt`}
              </button>
            )}
          </>
        ) : null}
          {orderID && <span className="order-id">ID:{orderID}</span>}
      </div>}

      {children && <div className="content">{children}</div>}
    </div>
  );
};

export default PageComponent;
