import React, { useEffect, useState, useContext, useRef } from "react";
import creation from "../../../../../assets/creation-icon.svg";
import person from "../../../../../assets/person-icon.png";
import { TEAM_ELEMENTS } from "../TeamBoxAcc/enums";
import { handleDropDown, handleDropdownElement } from "../helper/handleFocus";
import ENUMS from "../ENUMS";
import { UserContext } from "../../../../wrapper/AuthWrap";
import checkPermissions from "../../../../../functions/checkPermissions";
import { ReactComponent as DropdownIcon } from "../../../../../assets/dropdownicon.svg"
import selectBox from "./SelectBox";
const SelectBox = ({ persons, selectBoxData, setSelectBoxData, error, setError }) => {
  const [userList, setUserList] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const teamRef = useRef();
  const personsRef = useRef();
  const dropDownRef = useRef([]);

  //handle person droodown
  useEffect(() => {
    let newUserList = persons.filter((el) => {
      if (el.pk !== user.pk) {
        let permissions = el.permissions;
        if (selectBoxData.role == ENUMS.ROLE.DTP && permissions.includes("ecommerce_dtp")) {
          return el;
        } else if (selectBoxData.role == ENUMS.ROLE.KREACJA && permissions.includes("ecommerce_creation")) {
          return el;
        } else if (selectBoxData.role == ENUMS.ROLE.ACCM && permissions.includes("ecommerce_admin")) {
          return el;
        } else if (selectBoxData.role == ENUMS.ROLE.CLIENT && permissions.includes("ecommerce_orders_create")) {
          return el;
        }
      }
    });

    setUserList(newUserList);
  }, [selectBoxData]);

  return (
    <div className="select-box-container">
      <div className={`custom-input ${error ? 'error' : null}`} onClick={(e) => handleDropDown(e, teamRef, personsRef)}>
        <span>{selectBoxData.role == ENUMS.ROLE.CLIENT ? user.client_name : !selectBoxData.role ? "Wybierz dział" : selectBoxData.role}</span>
        <div id={TEAM_ELEMENTS.SELECT_TEAM_LIST} className="list z-index-first show-element" ref={teamRef}>
          {checkPermissions(user, ["ecommerce_admin", "ecommerce_creation"]) ? (
            <div
              className="list-option"
              onClick={(e) => {
                if(setError)setError(false);
                setSelectBoxData({
                  ...selectBoxData,
                  role: ENUMS.ROLE.KREACJA,
                });
                handleDropdownElement(personsRef, teamRef);
              }}>
              <p className="name">{ENUMS.ROLE.KREACJA}</p>
            </div>
          ) : null}
          {checkPermissions(user, ["ecommerce_admin", "ecommerce_dtp"]) ? (
            <div
              className="list-option"
              onClick={(e) => {
                if(setError)setError(false);
                setSelectBoxData({ ...selectBoxData, role: ENUMS.ROLE.DTP });
                handleDropdownElement(personsRef, teamRef);
              }}>
              <p className="name">{ENUMS.ROLE.DTP}</p>
            </div>
          ) : null}
          {checkPermissions(user, ["ecommerce_admin"]) ? (
            <div
              className="list-option"
              onClick={(e) => {
                if(setError)setError(false);
                setSelectBoxData({ ...selectBoxData, role: ENUMS.ROLE.ACCM });
                handleDropdownElement(personsRef, teamRef);
              }}>
              <p className="name">{ENUMS.ROLE.ACCM}</p>
            </div>
          ) : null}
          {checkPermissions(user, ["ecommerce_orders_create"]) ? (
            <div
              className="list-option"
              onClick={(e) => {
                if(setError)setError(false);
                setSelectBoxData({ ...selectBoxData, role: ENUMS.ROLE.CLIENT });
                handleDropdownElement(personsRef, teamRef);
              }}>
              <p className="name">{user.client_name}</p>
            </div>
          ) : null}
        </div>
        <DropdownIcon className='arrow-down'/>
      </div>
      <div onClick={(e) => handleDropDown(e, teamRef, personsRef)} id={TEAM_ELEMENTS.SELECT_PERSON} className={`custom-input ${error ? 'error' : null}`}>
        <span>{!selectBoxData.person ? "Wybierz osobę" : selectBoxData.person.split(' ')[0]}{' '}<strong>{selectBoxData.person.split(' ')[1]}</strong></span>
        <div className="list z-index-first show-element" ref={personsRef}>
          {userList.map(({pk, first_name, name, surname}, index) => {
            return (
                <div
                key={index}
                className="list-option"
                onClick={(e) => {
                  if(setError)setError(false);
                  setSelectBoxData({
                    ...selectBoxData,
                    person: first_name || pk,
                    id: pk,
                  });
                  handleDropdownElement(null, personsRef);
                }}>
                <p className="name">{first_name || pk} </p>
              </div>
            );
          })}
        </div>
        <DropdownIcon className='arrow-down' />
      </div>
    </div>
  );
};

export default SelectBox;
