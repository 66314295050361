import React, { useContext, useState, useRef, useEffect } from "react";
import { TableDataContext } from "../../../../Router";
import Correction from "../InProgressPopups/Correction";
import { ActiveAcceptPopupContext, ActiveCorrectionPopupPopupsContext } from "./CollapseRow";
import Slider from "../Slider";
import PERMISSIONS_ENUMS from "../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions from "../../../../../functions/checkPermissions";
import { UserContext } from "../../../../wrapper/AuthWrap";
import imageServices from "../../../../services/imageServices";
import ImageModal from "../../Popup/ImageModla";
import { MainAppContext } from "../../../../MainAppComponent";
import Image from "./bannerElements/Image";
import checkIfSourceIsPDF from "../../../../../functions/checkIfSourceIsPDF";
import LinksView from "./bannerElements/LinkView";
import imageFilterByStatus from "../../../../../functions/imageFilterByStatus";
import getClonedImage from "../../../../../functions/getClonedImage";
import AddFileWrapper from "../../AddFile/elements/AddFileWrapper";
import FileSender from "../../Team/helper/fileSender";
import ERROR_MESSAGE from "../../Popup/ENUMS/ERROR_POPUP_ENUMS";
import REMOVE_IMAGE_POPUP_TYPES from "../../Popup/ENUMS/REMOVE_IMAGE_POPUP_TYPES";
import Popup from "../../Popup/Popup";
import POPUP_TYPES from "../../Popup/ENUMS/POPUP_TYPES";
import MP4 from "../../../../../assets/mp4.svg";
import { ReactComponent as AVI } from "../../../../../assets/avi.svg";
import { ReactComponent as SVG } from "../../../../../assets/svg.svg";
import usePopup from "../../../../helper/usePopup";
import ShowVideoPopup from "../../Popup/ShowVideoPopup";
import ShowSVGPopup from "../../Popup/ShowSVGPopup";
import ImageCarouselPopup from "../../Popup/ImageCarouselPopup";
import InfoPopup from "../../Popup/InfoPopup";
import SomethingWentWrong from "../../Popup/AddOrder/SomethingWentWrong";

const notAllowedStatus = ["in_progress", "correction", "ready_for_qa", "accepted_for_realization", "correction"];

const CollapseBanner = ({ data, orderID, setDisplayData, fetchedData, isReformat, variantID, rowData, setMainBannerFromClone }) => {
  const { user, setUser } = useContext(UserContext);
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { activeCorrectionPopup, setActiveCorrectionPopup } = useContext(ActiveCorrectionPopupPopupsContext);
  const { initPopup } = usePopup();
  const { popup } = useContext(MainAppContext);
  const [showImageModal, setShowImageModal] = useState(false);
  const [fetchedImagesData, setFetchedImagesData] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [mainBanner, setMainBanner] = useState({ src: null, image_pk: null, component: null });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [reloadComponent, setRealoadComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const getFileExtension = (url) => {
    // Sprawdzamy, czy URL jest typu string i nie jest pusty
    if (typeof url === "string" && url.length > 0) {
      // Dekodujemy URL, aby uniknąć problemów z zakodowanymi znakami
      const decodedUrl = decodeURIComponent(url);

      // Usuwamy wszelkie parametry zapytania, które mogą występować po nazwie pliku
      const fileName = decodedUrl.split("?")[0].split("#")[0];

      // Dzielimy ostateczną nazwę pliku względem znaku '.' i wyodrębniamy rozszerzenie
      const parts = fileName.split("/").pop().split(".");

      // Sprawdzamy, czy po podziale otrzymaliśmy więcej niż jedną część (co oznacza obecność rozszerzenia)
      if (parts.length > 1) {
        return parts.pop().toLowerCase(); // Zwracamy ostatnią część tekstu (rozszerzenie), zamienione na małe litery
      }
    }

    return ""; // Zwracamy pusty string, jeśli URL nie zawiera rozszerzenia
  };
  const handleBannerChose = (e, icon, el, index) => {
    let element = e.target;
    if (icon) {
      let slideIndex = parseInt(index) + 1;
      setMainBanner({
        src: el.image,
        extension: getFileExtension(el.image),
        image_pk: el.pk,
        component: <Image source={el.image} imagePK={el.pkl} index={index} handleImage={handleMainBaner} />,
      });
      setCurrentSlide(slideIndex);
    } else {
      let slideIndex = parseInt(element.dataset.index) + 1;
      setMainBanner({
        src: element.src,
        extension: getFileExtension(element.src),
        image_pk: element.dataset.imagePk,
        component: <Image source={element.src} imagePK={element.dataset.imagePk} index={element.dataset.index} handleImage={handleMainBaner} />,
      });
      setCurrentSlide(slideIndex);
    }
    setShadowActive(false);
    setShowImageModal(false);
  };

  const generateImages = () => {
    imageServices.getImages(orderID, variantID, isReformat).then((res) => {
      let banners = [],
        mainbanner = { src: null, image_pk: null, component: null };

      let filteredImagesByStatus = imageFilterByStatus(res, tableContext.tableType);
      filteredImagesByStatus.forEach((el, index) => {
        if (el?.image) {
          if (mainbanner.src == null) {
            mainbanner = {
              src: el.image,
              image_pk: el.pk,
              extension: getFileExtension(el.image),
              component: <Image source={el.image} modified={el?.modified_at} imagePK={el.pk} index={index} handleImage={handleMainBaner} key={`image-${index}`} />,
            };
          }
          if (["mp4", "avi", "svg"].includes(getFileExtension(el.image))) {
            switch (getFileExtension(el.image)) {
              case "mp4":
                banners.push(<figure><img src={MP4} style={{width: 60, height: 60, objectFit: "cover"}} onClick={(e) => handleBannerChose(e, true, el, index)}></img></figure>);
                break;
              case "avi":
                banners.push(<AVI width={60} height={60} onClick={(e) => handleBannerChose(e, true, el, index)} />);
                break;
              case "svg":
                banners.push(<SVG width={60} height={60} onClick={(e) => handleBannerChose(e, true, el, index)} />);
                break;
            }
          } else {
            banners.push(<Image source={el.image} modified={el?.modified_at} imagePK={el.pk} index={index} handleImage={handleBannerChose} key={`image-${index}`} />);
          }
        }
      });

      let cloneImage = getClonedImage(res);

      setBannerList(banners);
      setFetchedImagesData(filteredImagesByStatus);
      setMainBannerFromClone(cloneImage);
      if (res[0]) {
        setMainBanner(mainbanner);
      } else {
        setMainBanner({ src: null, component: null });
      }
    });
  };

  useEffect(() => {
    generateImages();

    if (reloadComponent) setRealoadComponent(false);
  }, [variantID, reloadComponent]);

  const handleMainBaner = (e) => {
    let fileDataset = e.target.dataset.file;

    if (fileDataset && checkIfSourceIsPDF(fileDataset)) {
      window.open(fileDataset, "_blank");
    } else {
      setShowImageModal(true);
    }
  };
  useEffect(() => {
    if(showImageModal){
      initPopup(<ImageCarouselPopup type={bannerList.length > 1 ? `multiple` : "single"} src={mainBanner.src} images={generateImageModalList()} close={setShowImageModal}/>);
    }
  }, [showImageModal]);
  useEffect(() => {
    if(popup.display === false){
      setShowImageModal(false);
    }
  }, [popup]);
  const generateImageModalList = () => {
    let result = [mainBanner.component];

    fetchedImagesData.forEach((el, index) => {
      if (el.pk !== parseInt(mainBanner.image_pk)) {
        result.push(<Image source={el.image} imagePK={el.pk} index={index} handleImage={handleBannerChose} />);
      }
    });

    return result;
  };

  const sendFiles = async (variantID, index, filesList) => {
    if (index < filesList.length) {
      try {
        const r = await imageServices.createImageSlot(orderID, variantID);
        const slot_pk = r.pk;
        let senderResponse = await FileSender({ selectedFile: filesList[index] }, slot_pk);

        if ([500, 502].includes(senderResponse.status)) {
          throw new Error(`HTTP error! Status: ${senderResponse.status}`);
        } else {
          sendFiles(variantID, index + 1, filesList);
        }
      } catch (error) {
        console.error(error);
        initPopup(<SomethingWentWrong />)
        setErrorMessage(ERROR_MESSAGE.SENDING_IMAGE_ERROR);
        setLoading(false);
      }
    } else {
      setRealoadComponent(true);
      setLoading(false);
    }
  };

  const handleUploadFile = (e) => {
    let filesList = Array.from(e.target.files);
    setLoading(true);
    sendFiles(variantID, 0, filesList);
  };
  const handleShowVideo = (url) => {
    initPopup(<ShowVideoPopup url={url} />);
  };

  const displayFileIcon = () =>{
    switch(mainBanner.extension){
      case 'mp4':
        return <figure><img src={MP4} style={{width: 150, height: 150}} onClick={(e) => handleShowVideo(mainBanner.src)}></img></figure>
      case 'avi':
        return <AVI width={60} height={60} />
      case 'svg':
        return <SVG width={150} height={150} onClick={()=> initPopup(<ShowSVGPopup url={mainBanner.src} data={mainBanner} />)}/>
    }
  }
  return (
    <>
      <div className={`banner ${tableContext.tableType}`}>
        <span className="files-header">Pliki</span>
        {!notAllowedStatus.includes(rowData.order_status) || checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ALWAYS_SHOW_BANNER) ? (
          <div className="banner-wrapper">
            {(checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) && rowData.order_status !== 'finished') && (
              <AddFileWrapper loading={loading} handleInputFileChange={handleUploadFile} width={170} height={170} />
            )}
            {mainBanner.component && (
              <div className="main-banner">
                {["mp4", "avi", "svg"].includes(mainBanner.extension) ? (
                  displayFileIcon(mainBanner.extension)
                ) : (
                  mainBanner.component
                )}
              </div>
            )}
          </div>
        ) : null}
        {tableContext.tableType != "notification" &&
          (!notAllowedStatus.includes(rowData.order_status) || checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ALWAYS_SHOW_BANNER)) && (
            <>
              {mainBanner.component && bannerList.length > 0 ? (
                <>
                  <div className="small-banners">
                    {bannerList.length > 1 ? <Slider spaceBetween={17} slidesPerView={3} slides={bannerList} hasCustomNavigation={true} /> : null}
                  </div>
                  <LinksView
                    currentSlide={currentSlide}
                    mainBanner={mainBanner}
                    bannerList={bannerList}
                    type={tableContext.tableType}
                    orderID={orderID}
                    imagePk={mainBanner.image_pk}
                    setReloadComponent={setRealoadComponent}
                  />
                </>
              ) : null}

              {activeCorrectionPopup ? (
                <Correction fetchedData={fetchedData} banner={mainBanner.src} data={data} setDisplayData={setDisplayData} orderID={orderID} />
              ) : null}
            </>
          )}
      </div>
    </>
  );
};

export default CollapseBanner;
