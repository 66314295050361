import React, {useEffect, useState} from 'react';
import {ReactComponent as Warning} from "../../../../../assets/popups/warning.svg";
import usePopup from "../../../../helper/usePopup";
import timer from "../../../../../functions/timer";

const OrderUnderChanges = ({editInfo}) => {
    const {closePopup, clearTimeoutManually} = usePopup();
    const handleFirstButton = () => {
        closePopup();
    };
    const [timerValue, setTimerValue] = useState("00:00");

    useEffect(() => {
        if (editInfo) timer.displayCountDownTimer(editInfo.end_time, setTimerValue);
    }, []);
    return (
        <div className="order-undergoing-changes-popup" onClick={e => e.stopPropagation()}>
                <div className="upper-part-popup">
                    <Warning />
                    <h3>Zlecenie w trakcie <br/> edycji.</h3>
                </div>
                {
                    editInfo &&
                    <div className="middle-part-popup">
                        <span className="correction">korektę wprowadza: <strong>{editInfo.user_name}</strong></span>
                        <span className="correction">estymowany czas wprowadzenia zmian: <strong>{timerValue}</strong></span>
                    </div>
                }
            <div className="single-button">
                <button className="button accept" onClick={handleFirstButton}>OK</button>
            </div>
        </div>
    );
};

export default OrderUnderChanges;
