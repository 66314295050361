import React from 'react';

const RadioInput = ({ label, value, language, checked, onChange, ref, dataTextContent,dataType,dataCheckbox,dataCheckboxGroup }) => {
    return (
        <label className={`custom-radio ${language ? 'language-radio' : null}`}>
            <input
                data-text-content={dataTextContent}
                checked={checked}
                type="radio"
                data-checkbox={dataCheckbox}
                data-checkbox-group={dataCheckboxGroup}
                value={value}
                onChange={onChange}
                data-type={dataType}
                ref={ref}
            />
            <span className="custom-radio-checkmark"></span>
            {label}
        </label>
    );
}

export default RadioInput;
