import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import LogoTypeChooserPopup from "../Popup/LogoTypeChooserPopup";
import usePopup from "../../../helper/usePopup";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateGLobalBoxFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getActualBoxFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import noPhotoPlaceholder from "../../../../assets/no-photos.svg";
import Slider from "../Table/Slider";

const LogoTypeChooser = ({ actualVariant, boxFieldID }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const { initPopup } = usePopup();

  const [buttonChooserData, setButtonChooserData] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [showTooltip, setShowTooltip] = useState({ type: false, id: undefined });
  const timeoutIdRef = useRef(null); // Ref for managing timeout

  useEffect(() => {
    //initial data
    let actualBoxFieldState = getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant);
    if (actualBoxFieldState?.value?.length > 0) {
      setButtonChooserData(actualBoxFieldState.value.filter((el)=> el.id || el.url || el.name)); //filter is solution fol older orders with older functionality 
    } 
    if (actualBoxFieldState?.custom_inputs_value) setAdditionalInfo(actualBoxFieldState.custom_inputs_value);
  }, []);

  useEffect(() => {
    //save new data
    setBoxFieldContent();
  }, [additionalInfo, buttonChooserData]);

  const setBoxFieldContent = () => {
    let initialValue = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      value: buttonChooserData,
      custom_inputs_value: additionalInfo,
    };
    updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  const saveData = ({ type, id, url, name }) => {
    setButtonChooserData((prevState)=> [...prevState, { type, id, url, name}])
  };

  const removeData = (chooserIndex) => {
    setButtonChooserData(buttonChooserData.filter((el, index)=> index !== chooserIndex))
  };

  const handleChooserButton = (e, index) => {
    e.stopPropagation();
    let initialData = { type: index, id: null, url: "", name: "" };
    initPopup(<LogoTypeChooserPopup saveData={saveData} removeData={removeData} initialData={initialData} />);
  };

  const handleMouseEnter = (index) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current); // Clear the existing timeout
    }
    timeoutIdRef.current = setTimeout(() => {
      setShowTooltip({ type: true, id: index });
    }, 300); // Delay the appearance of the tooltip
  };

  const handleMouseLeave = (index) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current); // Clear the existing timeout
    }
    timeoutIdRef.current = setTimeout(() => {
      setShowTooltip({ type: false, id: index });
    }, 300); // Delay the disappearance of the tooltip
  };

  const slideArr = useMemo(() => {
    //render slider elements
    const slides = buttonChooserData.map((value, index) => (
      <div className="column">
        <div
          className="logotype"
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          {index + 1}
          <div id="tooltip" className={showTooltip.type && showTooltip.id === index ? "tooltipVisible" : ""}>
            <span onClick={(e) => removeData(index, e)}>Usuń</span>
          </div>
        </div>
        <div className="view">
          <figure>
            <img src={value.url ?? noPhotoPlaceholder}></img>
          </figure>
        </div>
      </div>
    ));

    // Add "add-new-logotype" button at the end
    slides.push(
      <button
        className="add-new-product"
        onClick={(e) => handleChooserButton(e, slides.length)}
        key="add-new-product"
      >
        +
      </button>
    );

    return slides;
  }, [buttonChooserData, showTooltip]);

  return (
    <div className="logo-chooser">
      <div className="chooser-wrapper">
        {slideArr.length ? (
          <Slider
          spaceBetween={0}
            slidesPerView={8}
            slides={slideArr}
            hasCustomNavigation={false}
            breakpoints={{ 2400: { slidesPerView: 10 }, 1600: { slidesPerView: 7 }, 772: { slidesPerView: 7 }, 320: { slidesPerView: 1 } }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default LogoTypeChooser;
