import React from "react";
import POPUP_TYPES from "../../elements/e-commerce/Popup/ENUMS/POPUP_TYPES";

const ActionDataButtons = ({ handleActionButton, isCorrection, reformat }) => {
  return (
    <div className="action-data">
      <button className="send" data-name={POPUP_TYPES.POSITIVE} onClick={handleActionButton}>
          { isCorrection ? "Wyślij koretkę" : "Wyślij do realizacji"}
      </button>
        {
            !isCorrection && <button className={"save"} data-name={POPUP_TYPES.NEUTRAL} onClick={handleActionButton}>
                Zapisz szkic
            </button>
        }
        <button className="cancel" data-name={POPUP_TYPES.NEGATIVE} onClick={handleActionButton}>
            { isCorrection ? "Anuluj" : "Usuń zlecenie"}
        </button>
    </div>
  );
};

export default ActionDataButtons;
