import React, { useState, useContext, useEffect, createContext } from "react";
import OrderBox from "./OrderBox";
import ProductView from "../OrderBoxFields/Products/ProductView";
import { AddNewOrderContext, ProductViewContext } from "../../../pages/e-commerce/AddNewOrder";
import { useSearchParams } from "react-router-dom";
import usePopup from "../../../helper/usePopup";

export const BoxesLanguageContext = createContext(null);

const OrderBoxContent = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const { boxData, setBoxData } = useContext(AddNewOrderContext);
  const { setProductViewData } = useContext(ProductViewContext);
  const [productBoxID, setProductBoxID] = useState(null);

  const [col_1_elements, set_col_1_elements] = useState([]);
  const [col_2_elements, set_col_2_elements] = useState([]);
  const [col_3_elements, set_col_3_elements] = useState([]);
  const [activeProductView, setActiveProductView] = useState(false);
  const { initPopup } = usePopup();

  const getAddProductFieldID = () => {
    let resultID = null;
    for (let i = 0; i < boxData.length; i++) {
      let boxFields = boxData[i].box.box_fields;
      for (let j = 0; j < boxFields.length; j++) {
        if (boxFields[j].field_type == "product") {
          resultID = boxFields[j].id;
          break;
        }
      }

      if (resultID) break;
    }

    return resultID;
  };
  const findProductViewData = () => {
    let addProductFieldID = getAddProductFieldID();
    let resultData;
    for (let i = 0; i < boxFieldsData.length; i++) {
      if (boxFieldsData[i].box_field == addProductFieldID) {
        resultData = boxFieldsData[i];
        break;
      }
    }

    return resultData;
  };
  useEffect(() => {
    let productView = findProductViewData();
    if (productView) {
      let variant_data;
      let productViewVariants = productView.variants;

      if (searchParams.get("order_status") || searchParams.get("edit_order")) {
        productViewVariants.forEach((el) => {
          if (el.variant_id == props.variant) {
            variant_data = el;
          }
        });
      } else {
        variant_data = productViewVariants[props.chosenTabIndex];
      }

      if (variant_data?.value.length > 0) {
        setProductViewData(variant_data?.value.filter((el) => el.ean));
      }
    }
  }, [boxData, props.variant]);

  useEffect(() => {
    let col_1 = [],
      col_2 = [],
      col_3 = [];

    boxData.forEach((value, index) => {
      if (value.box.box_fields[0]?.field_type == "product") {
        setActiveProductView(true); // <---- new solution
        setProductBoxID(value.box.id);
      }

      if (value.column == 1) {
        col_1.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            boxData={value}
            validate={props.validate}
            arget={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      } else if (value.column == 2) {
        col_2.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            validate={props.validate}
            boxData={value}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      } else if (value.column == 3) {
        col_3.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            validate={props.validate}
            boxData={value}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      }
    });

    set_col_1_elements(col_1);
    set_col_2_elements(col_2);
    set_col_3_elements(col_3);
  }, [boxData]);

  return (
    <div className={props.visible ? "box-content" : "box-content inactive"} data-target={props.target}>
      <div className={props.visible ? "box-wrapper" : "box-wrapper inactive"} data-target={props.target}>
        <div className="row">
          <div className="column col-1">{col_1_elements}</div>
          <div className="column col-2">{col_2_elements}</div>
          <div className="column col-3">{col_3_elements}</div>
        </div>
        {activeProductView ? <ProductView keyValue={`${props.target}-view`} productBoxID={productBoxID} variant={props.variant} /> : null}
      </div>
    </div>
  );
};

export default OrderBoxContent;
