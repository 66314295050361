import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import checkPermissions from "../../../../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import orderServices from "../../../../../services/orderServices";
import Popup from "../../../Popup/Popup";
import POPUP_TYPES from "../../../Popup/ENUMS/POPUP_TYPES";
import OrderEditInfo from "../OrderEditInfo";
import ChangesErrorPopup from "../../../Popup/ChangesErrorPopup";
import usePopup from "../../../../../helper/usePopup";
import OrderUnderChanges from "../../../Popup/AddOrder/OrderUnderChanges";

const EditButton = ({ status, orderID, type, rowData }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();

  const checkIfLockIsOlder = () =>{
    let currentDate = new Date().getTime();
    let lockTime = new Date(rowData.order_locks[0].end_time).getTime();

    return currentDate > lockTime
  }

  const handleEditBtn = async (repeat = 3) => {
    let link;
    let res = await orderServices.creatOrderLock(orderID, 900);
    if(repeat > 0){
      if([400, 423, 401].includes(res.status)){
        if(rowData.order_locks.length > 0){
          if(checkIfLockIsOlder() || user.pk == rowData.order_locks[0].user){
            //temporary solution, backend should do this in future
            await orderServices.removeOrderLock(rowData.order_locks[0].id);
            handleEditBtn(repeat-1);
          }
          else{
           initPopup(<OrderUnderChanges editInfo={rowData.order_locks[0]}/>)
          }
        }else{
          initPopup(<OrderUnderChanges editInfo={rowData.order_locks[0]}/>)
        }
      }else{
        if (rowData.is_reformat) {
          link = `/reformatting/?edit_order=true&order=${orderID}&reformat_id=${rowData.order_reformat.id}`;
        } else if (type == "draft") {
          link = `/addneworder/?edit_order=true&order=${orderID}`;
        } else {
          link = `/addneworder/?edit_order=true&block_edit=true&order=${orderID}`;
        }

        let data = await res.json();
        navigate(link+`&lock_id=${data.id}`);
      }
    }
  };
  return (
    <>
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.EDIT_BUTTON) && (
        <button className={`draft-edit ${rowData.order_status === 'accepted_for_realization' && user.permissions.includes('ecommerce_orders_create') && 'casual'}`} onClick={() => handleEditBtn()}>
          Edytuj
        </button>
      )}
    </>
  );
};

export default EditButton;
