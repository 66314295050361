/**
 * generateDisplayData() - A method that generates the structure of data displayed in tables
 *
 * example usage: CollapseRow.js, CorrectionList.js
 *
 * @param {array} data - data of box fields values
 * @param {integer} variantIndex - variant ID
 * @returns filtered data structure to display in tables
 */

import generateTableContent from "./generateTableContent";

const generateDisplayData = (data, variantIndex, compare, customSizeList) => {
  let filteredData = data.map((el) => {
    let is_size = false,
      is_product = false,
      is_global_order_value = false;
    let currentVariantValue = "",
      fieldType,
      variantID,
      boxFieldID;
    if (el.variants) {
      let calculatedContent = el.variants[variantIndex]?.value?.calculated_content;
      if (el.variants[variantIndex]?.value?.calculated_content) {
        let calculatedContentTitle = generateTableContent.generateCalculetedContent(calculatedContent);
        if (calculatedContent[0]?.default_name_placement == "right") {
          currentVariantValue += el.variants[variantIndex]?.custom_inputs_value ? el.variants[variantIndex]?.custom_inputs_value + " " : "";
          currentVariantValue += calculatedContentTitle;
        } else if (calculatedContent[0]?.default_name_placement == "left") {
          currentVariantValue += calculatedContentTitle;
          currentVariantValue += el.variants[variantIndex]?.custom_inputs_value ? el.variants[variantIndex]?.custom_inputs_value : "";
        } else if (el.variants[variantIndex]?.custom_inputs_value) {
          currentVariantValue += el.variants[variantIndex]?.custom_inputs_value;
        } else {
          currentVariantValue = calculatedContentTitle;
        }
      } else if (el.variants[variantIndex]?.value[0]?.ean) {
        if (compare) {
          let productList = el.variants[variantIndex]?.value;

          productList.forEach((product, index) => {
            currentVariantValue += product.ean;

            if (index + 1 < productList.length) currentVariantValue += ", ";
          });
        } else {
          let productsArr = el.variants[variantIndex]?.value;
          let variant_id = el.variants[variantIndex]?.variant_id;
          is_product = true;
          currentVariantValue = generateTableContent.generateProductContent(productsArr, variant_id);
        }
      } else if (el.variants[variantIndex]?.value[0]?.type) {
        let logosArr = el.variants[variantIndex]?.value;
        let variant_id = el.variants[variantIndex]?.variant_id;
        let customInputsValue = el.variants[variantIndex]?.custom_inputs_value
        is_product = true;
        currentVariantValue = generateTableContent.generateLogotypeContent(logosArr, variant_id, customInputsValue);
      } else {
        currentVariantValue = el.variants[variantIndex]?.custom_inputs_value
          ? el.variants[variantIndex].custom_inputs_value
          : el.variants[variantIndex]?.value.content || "";
      }

      variantID = el.variants[variantIndex]?.variant_id;

      fieldType = "variants";
    } else if (el.order) {
      is_global_order_value = true; //A-163 solution
      
      if (el.order[0]?.value?.calculated_content) {
        currentVariantValue = generateTableContent.generateCalculetedContent(el.order[0].value.calculated_content);
      }else if (el.order[0].value?.children) {
        is_size = true;
        currentVariantValue = generateTableContent.generateCalculetedContent(el.order[0].value.children);
        
      }else {
        currentVariantValue = el.order[0].custom_inputs_value ? el.order[0].custom_inputs_value : el.order[0].value.content || "";
      }

      variantID = el.order[0].order_id;
      fieldType = "order";
    } else {
      currentVariantValue = "";
    }

    boxFieldID = el.box_field;

    return {
      name: el.name,
      value: currentVariantValue,
      type: fieldType,
      boxFieldID: boxFieldID,
      variantID: variantID,
      is_size: is_size,
      is_product: is_product,
      is_changed: el.is_changed,
      setting: el.settings,
      is_global_order_value: is_global_order_value
    };
  });

  return filteredData;
};

export default generateDisplayData;
