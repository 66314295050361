import React from 'react';
import {ReactComponent as Warning}  from "../../../../../assets/popups/warning.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const VariantContinue = ({setChangeIndividualCheckboxValue}) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    const handleFirstButton = () => {
        closeOrderPopup();
        setChangeIndividualCheckboxValue(true);
    }

    return (
        <div className='abort-popup'>
            <Warning />
            <h3>Zmiana wpłynie <br /> na pozostałe warianty.</h3>
            <p>Tej operacji nie można cofnąć.</p>
            <div className='buttons'>
                <button className="button cancel" onClick={()=> closeOrderPopup()}>Anuluj</button>
                <button className="button accept" onClick={handleFirstButton}>OK</button>
            </div>
        </div>
    );
}

export default VariantContinue;
