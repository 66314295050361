import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import CompareColumn from "./CompareColumn";
import correctionServices from "../../../services/correctionServices";
import {ReactComponent as CorrectionIcon} from "../../../../assets/team/order.svg";
import {ReactComponent as BriefIcon} from "../../../../assets/team/order.svg";
import generateDisplayData from "../../../../functions/generateDisplayData";
import variantsServices from "../../../services/variantsServices";
import usePopup from "../../../helper/usePopup";
import ErrorLoadingData from "../Popup/AddOrder/ErrorLoadingData";

const CompareVariantsContainer = () => {
  const navigate = useNavigate();
  const {initPopup} = usePopup();

  const [componentIsMounted, setComponentIsMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')));
  const [loadingData, setLoadingData] = useState(true);

  const [variantList, setVariantsList] = useState([]);

  const [variantGlobalData, setVariantGlobalData] = useState({
    order_id : searchParams.get('order'),
    variant_id : searchParams.get('variant_id'),
    correction_pk : searchParams.get('correction_pk'),
    pagination: searchParams.get('pagination'),
    prevPage: searchParams.get('prevPage'),
  })

  const [displayCorrectionData, setDisplatCorrectionData] = useState({
    old: [],
    new: [],
  })

  const initData = async (correctionPK) =>{
    let pk = correctionPK ? correctionPK : variantGlobalData.correction_pk

    await correctionServices.getCorrectionsDetails(pk).then(res=>{
      let old_boxes = res.boxes ? generateDisplayData(res.boxes, 0, false) : [];
      let new_boxes = res.boxes_new ? generateDisplayData(res.boxes_new, 0, false) : [];

      setDisplatCorrectionData({old: old_boxes, new: new_boxes})
    }).catch((error) => {
      console.error(error);
      initPopup(<ErrorLoadingData type={'negative'} info={'data_error'}/>)
    });

    setLoadingData(false);
  }

  useEffect(()=>{
    variantsServices.getVariants(variantGlobalData.order_id).then(res=>{
      setVariantsList(res);
    }).catch((error) => {
      console.error(error);
      initPopup(<ErrorLoadingData type={'negative'} info={'data_error'}/>)
    });

    initData();
    setComponentIsMounted(true);
  }, [])

  useEffect(()=>{
    //handle page and variant_id in link when page is changed
    if (componentIsMounted) {
      setLoadingData(true);
      correctionServices.getCorrectionsList(variantList[page].id).then(res=>{
        if(res.length && res[res.length-1].pk){
          searchParams.set('correction_pk', res[res.length-1].pk)
          setVariantGlobalData({...variantGlobalData, correction_pk: res[res.length-1].pk})
          initData(res[res.length-1].pk);
        }else{
          setDisplatCorrectionData({old: [], new: []})
          setLoadingData(false);
        }
        searchParams.set('page', page)
        searchParams.set('variant_id', variantList[page].id)
        setSearchParams(searchParams);
      })
    }
  }, [page])

  const handleButton = () =>{
    if(variantGlobalData.prevPage){
      const searchParams = new URLSearchParams({
        order: variantGlobalData.order_id,
        pagination: variantGlobalData.pagination || 1,
      }).toString();

      navigate(`/${variantGlobalData.prevPage}?${searchParams}`)
    }else{
      navigate(-1)
    }
  }

  return (
    <div className="compare-variants">
      <div className="wrapper">
        <div className="row">
          <CompareColumn
            icon={<BriefIcon/>}
            dataList={displayCorrectionData.old}
            title={"Pierwotnie"}
            subtitle={"stan przed wprowadzeniem korekty do zlecenia"}
            loadingData={loadingData}
            columnType={'first'}
          />
          <CompareColumn
            icon={<CorrectionIcon/>}
            dataList={displayCorrectionData.new}
            title={"Korekta"}
            subtitle={"wartości do zmiany"}
            page={page}
            setPage={setPage}
            variantList={variantList}
            loadingData={loadingData}
            columnType={'correction'}
            showNoDataInfo={true}
          />
        </div>
        <div className="action-buttons">
          <button className="second-button" onClick={handleButton}>← Powrót do poprzedniego okna </button>
        </div>
      </div>
    </div>
  );
};

export default CompareVariantsContainer;
