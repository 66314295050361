import React, { createContext, useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import SourceFile from "../../elements/e-commerce/Reformatting/SourceFile";
import Formats from "../../elements/e-commerce/Reformatting/Formats";
import ReformatingActionName from "../../elements/headers/Refortmating/ReformatingActionName";
import ReformatingCampaignDropdown from "../../elements/headers/Refortmating/ReformatingCampaignDropdown";
import ReformatingCalenderDeadline from "../../elements/headers/Refortmating/ReformatingCalenderDeadline";
import { MainAppContext } from "../../MainAppComponent";
import reformatServices from "../../services/reformatServices";
import getBoxFields from "../../services/getBoxFields";
import orderServices from "../../services/orderServices";
import PERMISSIONS_ENUMS from "../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions from "../../../functions/checkPermissions";
import { UserContext } from "../../wrapper/AuthWrap";
import SOURCE_FILE_TYPE from "../../elements/e-commerce/Reformatting/enums/SOURCE_FILE_TYPES";
import ActionDataButtons from "./ActionData";
import generateReformatBody from "../../../functions/reformatsFunctions/generateReformatBody";
import checkIfSizesAreValidated from "../../../functions/reformatsFunctions/checkIfSizesAreValidated";
import updateCorrectionBoxFields from "../../services/updateBoxFieldValue";
import Loader from "../../elements/globalElements/Loader/Loader";
import LOADER_TYPE from "../../elements/globalElements/Loader/LOADER_TYPE";
import imageServices from "../../services/imageServices";
import TextInputBoxes from "../../elements/e-commerce/Reformatting/TextInputBoxes";
import { useNavigate } from 'react-router-dom';
import NewOrderPopup from "../../elements/e-commerce/Popup/NewOrderPopup";
import usePopup from "../../helper/usePopup";
import timer from "../../../functions/timer";
import TimerPopup from "../../elements/e-commerce/Popup/TimerPopup";
import removeOrderLockOnLocationChange from "../../../functions/removeOrderLockOnLocationChange";
import FillMissingInputs from "../../elements/e-commerce/Popup/AddOrder/FillMissingInputs";
import ReformatCancelProceeding from "../../elements/e-commerce/Popup/AddOrder/ReformatCancelProceeding";
import Success from "../../elements/e-commerce/Popup/AddOrder/Success";
import ErrorLoadingData from "../../elements/e-commerce/Popup/AddOrder/ErrorLoadingData";
import ReformatDelete from "../../elements/e-commerce/Popup/AddOrder/ReformatDelete";
import SomethingWentWrong from "../../elements/e-commerce/Popup/AddOrder/SomethingWentWrong";

export const ReformatContext = createContext(null);
export const ShowImageContext = createContext(null);
let globalTimerId = null;
const TIMER_ELEMENT_ID = 'navbar-timer';

const Refomatting = () => {
  const { user, setUser } = useContext(UserContext);
  const [reloadComponent, setReloadComponent] = useState(false);
  const [chosenOrder, setChosenOrder] = useState({ order_id: null, order_name: null });
  const [chosenSource, setChosenSource] = useState({ pk: null, image: null });
  const [sourceType, setSourceType] = useState(SOURCE_FILE_TYPE.ORDER_ID);
  const [showBoxInfo, setShowBoxInfo] = useState(false);
  const [editReformatID, setEditReformatID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [validationStatus, setValidationStatus] = useState({
    order_name: true,
    campaign_name: true,
    deadline: true,
    order_source: true,
    sizes: true,
  });

  const [displayTimerPopup, setDisplayTimerPopoup] = useState(false);
  const [orderLocks,setOrderLocks] = useState(null);

  const navigate = useNavigate();

  const [reformatData, setReformatData] = useState({
    order_id: null,
    variant_id: null,
    order_name: null,
    basic_sizes_list: null,
    custom_sizes_list: [],
    send_custom_sizes_list: false,
    additional_info: null,
    own_file: null,
    external_url: null,
    campaign_name: null,
    action_name: null,
    deadline: null,
    brief: '',
    correction: ''
  });
  const [showImage, setShowImage] = useState(false);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [headerBoxes, setHeaderBoxes] = useState([]);

  const [headersData, setHeadersData] = useState([]);

  const [popupType, setPopupType] = useState(null);
  const reformatValue = useMemo(() => ({ reformatData, setReformatData }), [reformatData, setReformatData]);
  const { totalRemovePopup, setTotalRemovePopup, generalSettings, setGeneralSettings } = useContext(MainAppContext);
  const { initPopup, initCustomBackgroundPopup, initTimeoutPopup } = usePopup();

  useEffect(() => {
    let cleanupFunction;

    if (searchParams.get("edit_order") === "true" && orderLocks) {
      cleanupFunction = timer.initCorrectionTimer(orderLocks.end_time);
    }

    return () => {
      if (cleanupFunction) cleanupFunction();
    };
  }, [searchParams, orderLocks]);

  const validateReformatData = () => {
    let validate = { ...validationStatus };
    let globalValidate = true;

    if (reformatData.order_id || reformatData.own_file || reformatData.external_url) {
      validate.order_source = true;
    } else {
      validate.order_source = false;
      globalValidate = false;
    }

    if (checkIfSizesAreValidated(reformatData.basic_sizes_list, reformatData.custom_sizes_list)) {
      validate.sizes = true;
    } else {
      validate.sizes = false;
      globalValidate = false;
    }

    if (reformatData.campaign_name) {
      validate.campaign_name = true;
    } else {
      validate.campaign_name = false;
      globalValidate = false;
    }

    if (reformatData.order_name) {
      validate.order_name = true;
    } else {
      validate.order_name = false;
      globalValidate = false;
    }

    if (reformatData.deadline) {
      validate.deadline = true;
    } else {
      validate.deadline = false;
      globalValidate = false;
    }

    setValidationStatus(validate);

    return globalValidate;
  };

  useEffect(() => {
    if (reloadComponent) {
      setChosenOrder({ order_id: null, order_name: null });
      setReformatData({
        order_id: null,
        variant_id: null,
        order_name: null,
        basic_sizes_list: [],
        custom_sizes_list: [],
        send_custom_sizes_list: false,
        additional_info: null,
        own_file: null,
        external_url: null,
        campaign_name: null,
        action_name: null,
        deadline: null,
        brief: '',
        correction: ''
      });

      setShowActionPopup(false);
      setHeaderBoxes([]);
      setHeadersData([]);
      setPopupType(null);
      setChosenSource({ pk: null, image: null });
      setReloadComponent(false);
      getHeadersBoxes();
    }
  }, [reloadComponent]);

  useEffect(() => {
    getHeadersBoxes();
    if (searchParams.get("edit_order")) initEditData(searchParams.get("order"));

  }, [generalSettings]);

  const getHeadersBoxes = async () => {
    let headerBoxesArr = [],
      boxDataArr = [];
    generalSettings.forEach((el) => {
      if (el.column < 0) {
        headerBoxesArr.push(el);
      } else {
        boxDataArr.push(el);
      }
    });

    setHeaderBoxes(headerBoxesArr);
    if (searchParams.get("create_format")) {
      let orderID = searchParams.get("order")
      let variant_id = searchParams.get("variant");
      let order_name = searchParams.get("order_name");
      let deadline = searchParams.get("deadline");

      setChosenOrder({ order_id: searchParams.get("order") });
      setReformatData({ ...reformatData, order_id: orderID, variant_id: variant_id, order_name: order_name, action_name: order_name, deadline: deadline });
      // setSourceType(SOURCE_FILE_TYPE.ORDER_ID);

      getFields(orderID);

      await imageServices.getImages(orderID, variant_id).then((res) => {
        let chosenImageSrc = null;
        let chosenImagePK = null;

        for(let i=0; i<res.length; i++){
          if(res[i].status == 'accepted'){
            chosenImageSrc=res[i].image;
            chosenImagePK=res[i].pk;
            break;
          }
        }
        if (res[0]) setChosenSource({ pk: chosenImagePK, image: chosenImageSrc });
      });

      setLoading(false);
    }else if(!searchParams.get("edit_order")){
      setLoading(false);
    }
  };

  const generateBoxStructure = (field) => {
    return { box_field: field.box_field, order: field.order };
  };

  const getFields = (id) => {
    getBoxFields
      .getVariantBoxFieldValue(id)
      .then((res) => {
        let newData = [];
        res.forEach((el) => {
          newData.push(generateBoxStructure(el));
        });
        setHeadersData(newData);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<ErrorLoadingData type={'negative'} info={'load_data'}/>)
      });
  };

  useEffect(() => {
    if (chosenOrder.order_id && chosenOrder) getFields(chosenOrder.order_id);
  }, [chosenOrder.order_id]);

  const handleHeaderBoxesClass = (box) => {
    let resultClass = "input-box";
    let boxPosition = box.position;

    if (boxPosition == 1) resultClass += " order-1";
    if (boxPosition == 2) resultClass += " order-2";
    if (boxPosition == 3) resultClass += " order-3";

    return resultClass;
  };

  const initSourceType = (fields) => {
    if (fields.own_file) return SOURCE_FILE_TYPE.OWN_FILE;
    else if (fields.external_url) return SOURCE_FILE_TYPE.EXTERNAL_URL;
    else return SOURCE_FILE_TYPE.ORDER_ID;
  };

  const initEditData = (order_id) => {
    reformatServices.getReformatByID(searchParams.get("reformat_id")).then((response) => {
      setEditReformatID(response.id);

      setChosenOrder({ order_id: searchParams.get("order"), order_name: response.action_name.content });
      let reformat = {
        order_id: response.order,
        variant_id: response.variant_id || null,
        order_name: response.action_name.content,
        basic_sizes_list: JSON.parse(response.basic_size_list),
        custom_sizes_list: JSON.parse(response.custom_size_list) || [],
        send_custom_sizes_list: false,
        additional_info: response.additional_info,
        own_file: response.own_file,
        external_url: response.external_url,
        campaign_name: response.campaign_name.content,
        action_name: response.action_name.content,
        deadline: response.deadline.content,
        brief: response.brief,
        correction: response.correction
      };
      setReformatData(reformat);

      let initedSourceType = initSourceType(response);

      // setSourceType(initedSourceType);
      if (initedSourceType == SOURCE_FILE_TYPE.ORDER_ID) {
        orderServices
          .getOrderImages(searchParams.get("order"))
          .then((res) => {
            if (res[0]) setChosenSource({ pk: res[0].pk || null, image: res[0].image || null });
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'load_data'}/>)
          });
      }
      orderServices.getOrderDetails(response.order).then(res=>{
        setOrderLocks(res.order_locks[0])
      })

      setLoading(false);
    }).catch(error=>{
      console.log('REFORMATING ERROR: line: 296');
      console.error(error);
      initPopup(<ErrorLoadingData type={'negative'} info={'load_data'}/>)
    });


  };

  const editReformat = (status) => {
    let order_id = searchParams.get("order");
    let bodyData = generateReformatBody.generateNewReformatBody(reformatData, sourceType, true);

    if (editReformatID) {
      reformatServices
        .updateReformat(editReformatID, bodyData)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<SomethingWentWrong type={'negative'} info={'save_data'}/>)
        });

      // if (status == "new") orderServices.changeOrderStatus(order_id, status);

      handleSendFile(editReformatID);

      updateCorrectionBoxFields(headersData).then((res) => {
        console.log("BOX UPDATED");
        console.log(res);
      });
    }
  };

  const removeReformat = () => {
    let order_id = searchParams.get("order");
    orderServices.changeOrderStatus(order_id, "deleted");
  };

  const handleSendFile = (refotmat_id) => {
    if (sourceType == SOURCE_FILE_TYPE.OWN_FILE && reformatData.own_file instanceof File) {
      reformatServices
        .sendFile(refotmat_id, reformatData.own_file)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<SomethingWentWrong type={'negative'} info={'send_file'}/>)
        });
    }
  };

  const generateReformat = (status) => {
    let bodyData = generateReformatBody.generateNewReformatBody(reformatData, sourceType, false);
    reformatServices
      .createNewReformat(bodyData)
      .then((res) => {
        orderServices.changeOrderStatus(res.order, status);
        handleSendFile(res.id);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<SomethingWentWrong type={'negative'} info={'save_data'}/>)
      });
  };

  const handleButton = (e) => {
    let editOrder = searchParams.get("edit_order");
    if(e.target.classList[0] === 'cancel'){
      if (editOrder) {
        initPopup(<ReformatDelete handleDelete={removeReformat} editOrder={editOrder}/>)
      }else{
        initPopup(<ReformatCancelProceeding type='negative' setReloadComponent={setReloadComponent}
                                            popupType={popupType}
                                            setShowActionPopup={setShowActionPopup}
                                            handleSaveProject={handleSaveProject}/>)
      }
      //else {
      //   generateReformat("deleted");
      // }
    }else if (validateReformatData()) {
      if (e.target.classList[0] == "send") {
        initPopup(<Success />)
        if (editOrder) {
          editReformat("new");
        } else {
          generateReformat("new");
        }
      } else if (e.target.classList[0] == "save") {
        initPopup(<NewOrderPopup type='neutral' setReloadComponent={setReloadComponent}
                                 popupType={popupType}
                                 setShowActionPopup={setShowActionPopup}
                                 handleSaveProject={handleSaveProject}/>)
        if (editOrder) {
          editReformat("draft");
        } else {
          generateReformat("draft");
        }
      } else {
        initPopup(<ReformatCancelProceeding type='negative' setReloadComponent={setReloadComponent}
                                 popupType={popupType}
                                 setShowActionPopup={setShowActionPopup}
                                 handleSaveProject={handleSaveProject}/>)
        if (editOrder) {
          removeReformat();
        } else {
          generateReformat("deleted");
        }
      }
      setShowActionPopup(true);
      if(searchParams.get("lock_id")) orderServices.removeOrderLock(searchParams.get("lock_id"));
    } else {
      initPopup(<FillMissingInputs />)
    }
  };
  useEffect(() => {
    if(totalRemovePopup){
      initCustomBackgroundPopup(<NewOrderPopup type='delete' pagetype={`add_order`} orderID={123} removeReformat={removeReformat}/>)
    }
  },[totalRemovePopup])
  const handleSaveProject = () => {
    if (reformatData.order_id) {
      orderServices.changeOrderStatus(reformatData.order_id, "draft");
      return true;
    }
    return false;
  };

  const handleNegativeBtn =()=>{
    navigate(-1)
  }
  useEffect(()=>{
    if(displayTimerPopup) {
      let timeDistance = new Date(orderLocks.end_time).getTime() - new Date().getTime();
      initTimeoutPopup(<TimerPopup orderLocks={orderLocks} setOrderLocks={setOrderLocks} displayPopup={displayTimerPopup} initCorrectionTimer={timer.initCorrectionTimer}/>, timeDistance)
      setDisplayTimerPopoup(false)
    } //TODO show timer popup
  }, [displayTimerPopup])

  useEffect(()=>{
    if(orderLocks){
      let countDownDate = new Date(orderLocks.end_time).getTime();
      timer.countDownTimer(countDownDate, setDisplayTimerPopoup);

      return () => {
          removeOrderLockOnLocationChange(orderLocks.id)
      };
    }
  }, [orderLocks])

  return (
    <>
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.REFORMAT_PAGE) ? (
        <>
          {loading ? (
            <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />
          ) : (
            <ReformatContext.Provider value={reformatValue}>
              <div className="reformatting-page">
                <div className="top-inputs">
                  {headerBoxes
                    ? headerBoxes.map((el, index) => {
                        return (
                          <div className={handleHeaderBoxesClass(el)} key={`add-new-order-key-${index}`}>
                            <h3>{el.box.name}</h3>
                            {el.box.box_fields[0].field_type == "input_text" ? (
                              <ReformatingActionName
                                sourceType={sourceType}
                                validate={validationStatus.action_name}
                                reloadComponent={reloadComponent}
                                boxID={el.box.box_fields[0].id}
                                data={headersData}
                                setData={setHeadersData}
                              />
                            ) : el.box.box_fields[0].field_type == "autocomplete" ? (
                              <ReformatingCampaignDropdown
                                sourceType={sourceType}
                                reloadComponent={reloadComponent}
                                validate={validationStatus.campaign_name}
                                value={el.box.box_fields[0].box_field_dictionary}
                                boxID={el.box.box_fields[0].id}
                                data={headersData}
                                setData={setHeadersData}
                              />
                            ) : (
                              <ReformatingCalenderDeadline
                                sourceType={sourceType}
                                reloadComponent={reloadComponent}
                                validate={validationStatus.deadline}
                                boxID={el.box.box_fields[0].id}
                                data={headersData}
                                setData={setHeadersData}
                              />
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="wrapper">
                  <div className="boxes">
                    <SourceFile
                      reloadComponent={reloadComponent}
                      validate={validationStatus.order_source}
                      sourceType={sourceType}
                      setSourceType={setSourceType}
                      setChosenOrder={setChosenOrder}
                      chosenOrder={chosenOrder}
                      setChosenSource={setChosenSource}
                      chosenSource={chosenSource}
                    />
                    <TextInputBoxes reformatData={reformatData} setReformatData={setReformatData} />
                  </div>
                  <Formats validate={validationStatus.sizes} editReformatID={editReformatID} />
                  <ActionDataButtons handleActionButton={handleButton} reformat isCorrection={searchParams.get("is_correction")} isEdit={searchParams.get("edit_order")}/>
                </div>
              </div>
            </ReformatContext.Provider>
          )}
        </>
      ) : (
        <span>brak uprawnień do korzystania z tej strony</span>
      )}
    </>
  );
};

export default Refomatting;
