import React, { useRef, useState, createContext, useMemo, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import Menu from "./elements/Menu";
import Header from "./elements/Header";
import removeSessionTimer from "../cetaMethods/removeSessionTimer";
import filterServices from "./services/filterServices";
import settingsService from "./services/settingsService";

import notificationBell from "../cetaMethods/notificationBell";
import hardReaload from "../cetaMethods/hardReaload";
import GlobalPopup from "./elements/e-commerce/Popup/GlobalPopup";
import icon from "../assets/version2.svg"
import * as Sentry from "@sentry/react";
import { UserContext } from "./wrapper/AuthWrap";
import AddNewPopupContainer from "./elements/e-commerce/Popup/AddNewPopupContainer";

export const MainAppContext = createContext(null)

const MainAppComponent = () => {
  const [totalRemovePopup, setTotalRemovePopup] = useState(false);
  const [isShadowActive, setShadowActive] = useState(false);
  const [usersList, setUsersList] = useState([])
  const [filterSettings, setFilterSettings] = useState(null);
  const [generalSettings, setGeneralSettings] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [popup, setPopup] = useState({ display: false, component: null, state: null, order:false });
  const {user} = useContext(UserContext);
  const initCorrectionTimer = () => {
    const cetaNavbar = process.env.REACT_APP_ENV === "local"
        ? document.querySelector("header .actions")
        : document.querySelector(".nav.navbar-top-links");

    // Sprawdź, czy timer już istnieje i usuń go

    const liElement = document.createElement("li");
    const spanElement = document.createElement("span");
    const spanElement2 = document.createElement("span");
    liElement.classList.add("version-name")
    spanElement.classList.add("version-text")
    spanElement2.classList.add(process.env.REACT_APP_ENV === "local" ? "version-name-local" : "version-name-staging")

    liElement.appendChild(spanElement);
    liElement.appendChild(spanElement2);
    let imgElement = document.createElement('img');
    imgElement.src = icon
    imgElement.alt = 'Ikona';
    imgElement.style.marginRight = '5px'
    imgElement.style.width = '20px'
    imgElement.style.height = '20px'
    spanElement.innerHTML = '';
    spanElement.appendChild(imgElement);
    spanElement.appendChild(document.createTextNode(`wersja:${' '}`));
    spanElement2.innerHTML = process.env.REACT_APP_COMMIT_ID.slice(0,5);
    setTimeout(() => {
      cetaNavbar.prepend(liElement);
    }, 1); // Opóźnienie 1ms
  };

  const initSentryUser = () =>{
    //init user for sentry to loggin
    Sentry.setUser({
      id: user.pk,
      email: `ID: ${user.pk}`,
    });
  }

  useEffect(()=>{
    initCorrectionTimer()
    initSentryUser()
  },[])

  useEffect(() => {
    removeSessionTimer();
    notificationBell.initNotificationBell();
    hardReaload.initHardReload();

    filterServices.getFilterSettings().then(res=>{
      setFilterSettings(res)
      setUsersList(res.users);
    }).catch(error=>{
      console.log("GET USERS LIST ERROR");
      console.error(error);
    })
    settingsService.getSetting().then((res) => {
      setGeneralSettings(res);
    })
    .catch((error) => {
      console.log("GET BOX SETTINGS ERROR");
      console.error(error);
    });
  }, []);
  return (
    <MainAppContext.Provider
      value={{
        totalRemovePopup, setTotalRemovePopup,
        isShadowActive, setShadowActive,
        usersList, setUsersList,
        filterSettings, setFilterSettings,
        generalSettings, setGeneralSettings,
        intervalId, setIntervalId,
        popup, setPopup
      }}
    >
      <div className="main-app-component">
        {process.env.REACT_APP_ENV === "local" ? (
          <div className="menu-section">
            <Menu />
            <div className="helpdesk-banner">
              <a href="#" target={`_blank`}></a>
            </div>
          </div>
        ) : null}

        <div className="content-section">
          {process.env.REACT_APP_ENV === "local" ? <Header /> : null}
          <GlobalPopup />
          <AddNewPopupContainer />
          <Outlet />
        </div>
      </div>
    </MainAppContext.Provider>
  );
};

export default MainAppComponent;
