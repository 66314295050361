import React from "react";
import TextBox from "./sectionsElements/TextBox";
import brief from "../../../../assets/reformats/brief.svg";
import correction from "../../../../assets/reformats/korekta.svg";

const boxesList = [
  { logo: brief, title: "Brief", subtitle: "KV, brief, uwagi, linki, oraz informacje kluczowe do realizacji zlecenia", type: "brief" },
  { logo: correction, title: "Korekta", subtitle: "użyj tego pola dopiero po utworzeniu zlecenia, celem zgłoszenia poprawek", type: "correction" },
];

const TextInputBoxes = ({ reformatData, setReformatData }) => {
  const handleSaveData = (newData, type) => {
    if (type == "brief") setReformatData({ ...reformatData, brief: newData });
    if (type == "correction") setReformatData({ ...reformatData, correction: newData });
  };

  const handleResetData = (type) => {
    if (type == "brief") setReformatData({ ...reformatData, brief: reformatData.brief != null ? null : '' });
    if (type == "correction") setReformatData({ ...reformatData, correction: reformatData.correction != null ? null : '' });
  };

  return (
    <div className="text-input-boxes">
      {boxesList.map((box) => {
        return (
          <TextBox
            title={box.title}
            logo={box.logo}
            subtitle={box.subtitle}
            type={box.type}
            handleSaveData={handleSaveData}
            handleResetData={handleResetData}
            value={reformatData[box.type]}
          />
        );
      })}
    </div>
  );
};

export default TextInputBoxes;
