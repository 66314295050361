import React, {useState} from 'react';
import Tooltip from "../../../globalElements/Tooltip";

const IconTooltip = ({text, iconClassName}) => {
    const [position, setPosition] = useState(null);

    const showTooltip = (event) => {
        const rect = event.target.getBoundingClientRect();
        setPosition({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
        });
    };

    const hideTooltip = () => {
        setPosition(null);
    };
    return (
        <div
            className={iconClassName}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {position && <Tooltip text={text} position={position} />}
        </div>
    );
}

export default IconTooltip;
