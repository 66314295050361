import React, { useContext, useState, useEffect } from "react";
import { MainAppContext } from "../../../../MainAppComponent";
import noPhotoPlaceholder from "../../../../../assets/no-photos.svg";
import { AddNewOrderContext, AddProductPopupContext, ProductViewContext } from "../../../../pages/e-commerce/AddNewOrder";
import handleDisplayProductView from "../helper/handleDisplayProductView";

const ProductView = ({ keyValue, productBoxID, variant }) => {
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { showPopup, setShowPopup } = useContext(AddProductPopupContext);
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);
  const [hideProductView, setHideProductView] = useState(false);

  const addNewProduct = () => {
    setShadowActive(true);
    setShowPopup({ ...showPopup, active: true });
  };

  const handleRemoveItem = (productIndex) => {
    setProductViewData(productViewData.filter((value, index) => index !== productIndex));
  };

  const getImageWidth = (index) => {
    return productViewData[index]?.width || 1;
  };

  const getImageHeight = (index) => {
    return productViewData[index]?.height || 1;
  };

  const getImageRotation = (index) => {
    return productViewData[index]?.rotate || 1;
  };

  const handleScale = (event, index, actionType) => {
    let parent = event.target.closest('.block');
    let width = getImageWidth(index);
    let height = getImageHeight(index);
    let newView = [...productViewData];
    let image = parent.querySelector("img");

    let imgWidth = image.clientWidth;

    if (actionType === "plus") {
      width += 20;
      height += 20;
      imgWidth += 20;

      image.style.width = `${imgWidth}px`;
    } else {
      if (imgWidth <= 20) {
        imgWidth = 20;
      } else {
        width -= 20;
        height -= 20;
        imgWidth -= 20;
      }

      image.style.width = `${imgWidth}px`;
    }

    newView[index] = { ...newView[index], width: width, height: height, display_width: imgWidth };
    setProductViewData(newView);
  };

  const handleRotate = (e, index) => {
    let parent = e.target.closest('.block');
    let rotate = getImageRotation(index);
    let newView = [...productViewData];

    let image = parent.querySelector("img");
    if (rotate >= 360) rotate = 0;
    rotate += 90;

    image.style.transform = `rotate(${rotate}deg)`;

    newView[index] = { ...newView[index], rotate: rotate };

    setProductViewData(newView);
  };

  const handleRefreshImage = (e, index) => {
    let parent = e.target.closest('.block');
    let image = parent.querySelector("img");
    let newView = [...productViewData];

    image.style.transform = `rotate(0deg) scale(1)`;

    newView[index] = { ...newView[index], rotate: 0, scale: 1 };
    setProductViewData(newView);
  };

  const handleWeight = (e, index) => {
    let newView = [...productViewData];
    newView[index] = { ...newView[index], grammage: e.target.value };
    setProductViewData(newView);
  };

  useEffect(() => {
    let boxes = boxSettings.hidden_boxes;
    setHideProductView(handleDisplayProductView(boxes, productBoxID, variant));
  }, [boxSettings]);

  return (
    <div className={hideProductView ? "view inactive" : "view"}>
      <div className="wrapper">
        <div className="title">
          <h2>Podgląd przykładowego ustawienia produktów i ich wielkości</h2>
        </div>
        <div className="products">
          <div className="products-wrapper">
            {productViewData
              ? productViewData.map((el, index) => {
                  return (
                    <>
                      <div className="product-block-wrapper" key={el.id}>
                        <div className="block" data-view={el.id}>
                          <div className="action">
                            <span className="letter">{el.id}</span>
                            <div className="icons">
                              <div className="icon minus" onClick={(e) => handleScale(e, index, "minus")}></div>
                              <div className="icon plus" onClick={(e) => handleScale(e, index, "plus")}></div>
                              <div className="icon rotate" onClick={(e) => handleRotate(e, index)}></div>
                              <div className="icon refresh" onClick={(e) => handleRefreshImage(e, index)}></div>
                              <div className="icon remove" onClick={() => handleRemoveItem(index)}></div>
                            </div>
                          </div>
                          <div className={el?.photos[0].url ? "photo" : "photo no-photo"}>
                            <figure>
                              <img
                                src={el?.photos[0].url  || noPhotoPlaceholder}
                                style={{ transform: `rotate(${el.rotate}deg)`, width: el.display_width }}
                              ></img>
                            </figure>
                          </div>
                        </div>
                        <div className="grammage">
                          <h4>Gramatura:</h4>
                          <input value={el.grammage} onChange={(e)=>handleWeight(e, index)}></input>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
            <button className="add-new-product" onClick={addNewProduct} key="add-new-product">
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
