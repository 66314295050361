import React, {useEffect, useState} from "react";
import AssignedProjectBox from "../../globalelements/AssignedProjectBox";
import {ReactComponent as Rocket} from "../../../../../../assets/rocket-lunch.svg";
import AssigneePopup from "../../../Popup/AssigneePopup";
import usePopup from "../../../../../helper/usePopup";

const ProjectTypeRow = ({
                            title,
                            data,
                            orange,
                            initializeNewWorkLog,
                            currentWorkProject,
                            setRealoadComponent,
                            projectList,
                            assigneToDtp,
                            setOrderId,
                            startProjectRef,
                            orderId,
                            showRocketStartProject,
                            projectAdditionalActionsRef
                        }) => {
    const [assignedPopup, setAssignePopup] = useState({showPopup: false, order_id: null});
    const {initPopup} = usePopup();
    useEffect(() => {
        if (assignedPopup.showPopup) {
            initPopup(<AssigneePopup setRealoadComponent={setRealoadComponent}
                                     setAssignePopup={setAssignePopup}
                                     orderID={assignedPopup.order_id}
                                     assigneToDtp={assigneToDtp}
                                     changeStatus={true}/>);
        }
    }, [assignedPopup])
    return (
        <div className="project-type-row-container">
            <div className="title-wrapper">
                <Rocket style={{width: "20px", height: "20px", marginRight: "8px"}}
                        fill={projectList ? `#FFB800` : `#87c16c`} stroke="#FFFFFF"/>
                <p style={{color: `${orange && "#FFB800"}`, fontSize: "16px"}}>{title}</p>
                <div className="border"></div>
            </div>
            <div className="content">
                {data.map((element, index) => {
                    return (
                        <AssignedProjectBox
                            setRealoadComponent={setRealoadComponent}
                            setAssignePopup={setAssignePopup}
                            currentWorkProject={currentWorkProject}
                            initializeNewWorkLog={initializeNewWorkLog}
                            data={element}
                            rocket
                            messageDtp
                            id={element.id}
                            key={index}
                            my_order
                            setOrderId={setOrderId}
                            startProjectRef={startProjectRef}
                            orderId={orderId}
                            showRocketStartProject={showRocketStartProject}
                            projectAdditionalActionsRef={projectAdditionalActionsRef}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ProjectTypeRow;
