import React, {useEffect} from "react";
import deleteImage from "../../../../../../assets/delete.png";
import copyToClipboard from "../../../../../../functions/copyToClipboard";
import TABLE_TYPES from "../../../../../../globalEnums/TABLE_TYPES_ENUMS";
import DeleteFilePopup from "../../../Popup/DeleteFilePopup";
import usePopup from "../../../../../helper/usePopup";
import DeleteFile from "../../../Popup/AddOrder/DeleteFile";

const LinksView = ({ currentSlide, mainBanner, bannerList, type, orderID, imagePk, setReloadComponent }) => {
  const { initPopup } = usePopup();

  return (
    <>
      <div className="links-view">
        {type !== TABLE_TYPES.DRAFT && (
          <>
            <div className="text">
              <h4>Podgląd aktualnego zlecenia</h4>
              <span>
                {" "}
                — ilość wersji {currentSlide ? currentSlide : 1} z {bannerList.length}
              </span>
            </div>
            <div className="text">
              <h4>LINK URL DO UDOSTĘPNIENIA ZLECENIA</h4>
            </div>
          </>
        )}
        <div className="links">
          <span className="link" data-value={`${window.location.origin}/order/${orderID}`} onClick={(e) => copyToClipboard(e)}>
            kliknij aby skopiować link
          </span>
        </div>
        {type !== TABLE_TYPES.DRAFT && (
          <div
            className="remove-image"
            onClick={(e) => initPopup(<DeleteFile orderID={orderID} imagePk={imagePk} setReloadComponent={setReloadComponent} />)}
          >
            <figure>
              <img src={deleteImage}></img>
            </figure>
            <span>Usuń</span>
          </div>
        )}
      </div>
    </>
  );
};

export default LinksView;
