import React, {useContext, useRef} from 'react';
import {ReactComponent as PlusIcon} from "../../../../../assets/plus.svg"
import {ReactComponent as MinusIcon} from "../../../../../assets/minus.svg"
import {ReactComponent as RotateIcon} from "../../../../../assets/rotate.svg"
import {ReactComponent as RefreshIcon} from "../../../../../assets/refresh.svg"
import {ReactComponent as RemoveIcon} from "../../../../../assets/remove.svg"
import noPhotoPlaceholder from "../../../../../assets/no-photos.svg";
import {AddNewOrderContext} from "../../../../pages/e-commerce/AddNewOrder";

const Product = ({index, el, productViewData, setProductViewData}) => {
    const { setBoxFieldsData } = useContext(AddNewOrderContext);
    
    const handleRemoveItem = (productIndex) => {
        setProductViewData(productViewData.filter((value, index) => index !== productIndex));
        setBoxFieldsData(prevBoxFieldsData => {
            return prevBoxFieldsData.map(boxField => {
                if (boxField.box_field !== 13) {
                    // If it's not the target box_field, return it unchanged
                    return boxField;
                } else {
                    // Modify the target box_field
                    const updatedVariants = boxField.variants.map(variant => {
                        // Assuming you want to modify the first variant
                        if (variant === boxField.variants[0]) {
                            return {
                                ...variant,
                                value: variant.value.filter((_, index) => index !== productIndex),
                            };
                        }
                        return variant;
                    });

                    return {
                        ...boxField,
                        variants: updatedVariants,
                    };
                }
            });
        });
    };

    const getImageWidth = (index) => {
        return productViewData[index]?.width || 1;
    };

    const getImageHeight = (index) => {
        return productViewData[index]?.height || 1;
    };

    const getImageRotation = (index) => {
        return productViewData[index]?.rotate ?? 0;
    };

    const imageRef = useRef(null);
    const handleScale = (event, index, actionType) => {
        //imgWidth and imgHeight is for visualization on front end
        //width and height are real sizes

        let parent = event.target.closest('.block');
        let width = getImageWidth(index); //image real width
        let height = getImageHeight(index); //image real height
        let newView = [...productViewData];
        let image = parent.querySelector("img");

        let imgWidth = image.clientWidth; //image height for display on front end
        let imgHeight = image.clientHeight; //image width for display on front end
        if (actionType === "plus") {
            width += 20;
            height += 20;
            imgWidth += 20;
            imgHeight += 20;
            image.style.width = `${imgWidth}px`;
            image.style.height = `${imgHeight}px`;
        } else {
            if (imgWidth <= 20) {
                imgWidth = 20;
            } else {
                width -= 20;
                height -= 20;
                imgWidth -= 20;
                imgHeight -= 20;
            }

            image.style.width = `${imgWidth}px`;
            image.style.height = `${imgHeight}px`;
        }
        newView[index] = { ...newView[index], width: width, height: height, display_width: imgWidth, display_height: imgHeight};
        setProductViewData(newView);
    };

    const handleRotate = (e, index) => {
        let parent = e.target.closest('.block');
        let rotate = getImageRotation(index);
        let newView = [...productViewData];

        let image = parent.querySelector("img");
        if (rotate >= 360) rotate = 0;
        rotate += 90;
        console.log(rotate);
        image.style.transform = `rotate(${rotate}deg)`;

        newView[index] = { ...newView[index], rotate: rotate };

        setProductViewData(newView);
    };

    const handleRefreshImage = (e, index) => {
        let parent = e.target.closest('.block');
        let image = parent.querySelector("img");
        let newView = [...productViewData];

        image.style.transform = `rotate(0deg) scale(1)`;

        newView[index] = { ...newView[index], rotate: 0, scale: 1 };
        setProductViewData(newView);
    };
    return (
        <div className="product-block-wrapper" key={`product-view-${el.id}`}>
            <div className="product-counter">{index + 1}</div>
            <div className="block" data-view={el.id}>
                <div className={el?.photos[0].url ? "photo" : "photo no-photo"}>
                    <img
                        src={el?.photos[0].url || noPhotoPlaceholder}
                        ref={imageRef}
                        style={{
                            width: el.display_width,
                            height: el.display_height,
                            transform: `rotate(${el.rotate ?? 0}deg)`,
                        }}
                    ></img>
                </div>
                <div className="icons">
                    <div className="icon plus" onClick={(e) => handleScale(e, index, "plus")}><PlusIcon/></div>
                    <div className="icon minus" onClick={(e) => handleScale(e, index, "minus")}><MinusIcon/></div>
                    <div className="icon rotate" onClick={(e) => handleRotate(e, index)}><RotateIcon/></div>
                    <div className="icon refresh" onClick={(e) => handleRefreshImage(e, index)}><RefreshIcon/></div>
                    <div className="icon remove" onClick={() => handleRemoveItem(index)}><RemoveIcon/></div>
                </div>
            </div>
        </div>
    );
}

export default Product;
