import React, {useState, useRef, useContext, useEffect} from "react";
import orderServices from "../../../../../services/orderServices";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import { ActiveAcceptPopupContext } from "../CollapseRow";
import { ReloadTableContext } from "../../../../../Router";
import BOX_INFO_ENUMS from "../../../Popup/ENUMS/BOX_INFO_ENUMS";
import Loader from "../../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../../globalElements/Loader/LOADER_TYPE";
import POPUP_TYPES from "../../../Popup/ENUMS/POPUP_TYPES";
import Popup from "../../../Popup/Popup";
import NewOrderPopup from "../../../Popup/NewOrderPopup";
import usePopup from "../../../../../helper/usePopup";
import InfoPopup from "../../../Popup/InfoPopup";
import OrderAccepted from "../../../Popup/AddOrder/OrderAccepted";

const AcceptButton = ({ status, orderID, navigate, rowData }) => {
  const [showBoxInfo, setShowBoxInfo] = useState(false);
  const buttonRef = useRef();
  const { user, setUser } = useContext(UserContext);
  const {setActiveAcceptPopup } = useContext(ActiveAcceptPopupContext);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const { initPopup } = usePopup();
  const handleOrderStatus = (status) => {
    orderServices
      .changeOrderStatus(orderID, status)
      .then((res) => {
        initPopup(<OrderAccepted setReloadComponent={setReloadComponent}/>)
        setActiveButtonLoader(false);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<InfoPopup type={'negative'} info={'accept_button'}/>)
        setActiveButtonLoader(false);
      });
  };

  const handleAcceptButton = (e) => {
    let accPerm = checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) ? true : false;
    if (accPerm && orderID) {
      setActiveButtonLoader(true);
      handleOrderStatus("accepted_acc", false);
    } else if (!rowData?.has_first_acceptation && !rowData.is_reformat) {
      // initPopup(<NewOrderPopup type='positive' oneButton={true} />);
      setActiveAcceptPopup(true);
    } else {
      if (orderID) {
        setActiveButtonLoader(true);
        handleOrderStatus("finished", true);
      }
    }
  };

  const handlePermissionClass = () => {
    let isAccc = checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]);
    if(isAccc){
      if(rowData.order_status === 'accepted_acc'){
        return 'casual'
      }else{
        return 'acc-perm'
      }
    }else if(user.permissions.includes('ecommerce_orders_create')){
      if(rowData.order_status === 'restored' || rowData.order_status === 'ready_for_qa'){
        return 'casual'
      }else{
        return 'acc-perm'
      }
    }
  };

  return (
    <>
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ACCEPT_BUTTON) && (
        <>
          <button className={`accept ${handlePermissionClass()}`} onClick={handleAcceptButton} ref={buttonRef}>
            {activeButtonLoader ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} /> : "Zaakceptuj"}
          </button>
        </>
      )}
    </>
  );
};

export default AcceptButton;
