import React from "react";
import { ReactComponent as Observe } from "../../../../../assets/popups/eye.svg";
import usePopup from "../../../../helper/usePopup";

const StartObserve = () => {
  const { closeOrderPopup } = usePopup();
  return (
    <div className="start-observe-popup">
        <div className="upper-part-popup">
            <Observe />
            <h3>Obserwujesz <br />wybrane zlecenie</h3>
        </div>
      <div className="buttons single-button ">
        <button className="button accept" onClick={() => closeOrderPopup()}>
          OK
        </button>
      </div>
    </div>
  );
};

export default StartObserve;
