import React, { useRef } from "react";
import TableHeaders from "../tableElements/TableHeaders";
import TableBody from "../tableElements/TableBody";
import Loader from "../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../globalElements/Loader/LOADER_TYPE";
;

const Table = ({ loadingTableData, orderBy, setOrderBy }) => {
  const tableRef = useRef();

  return (
    <div className="table" ref={tableRef}>
      <TableHeaders orderBy={orderBy} setOrderBy={setOrderBy} tableRef={tableRef}/>
      <TableBody />
      {loadingTableData && <Loader type={LOADER_TYPE.LOAD_DATA} width={25} height={25} position={`absolute`} startAnimation={true}/>}
    </div>
  );
};

export default Table;
