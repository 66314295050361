import React from "react";
import downloadFileService from "../../services/downloadFileService";
import { getFileBaseName } from "../../../functions/getFileBaseName";
import handleDownload from "../../../functions/handleDownload";

const DownloadDataSheet = ({ sheetType }) => {
  let buttonsType = ["XLSX"];

  const handleButton = (e) => {
    let buttonType = e.target.dataset.buttonType;
    let fileType;
    switch (buttonType) {
      case "pdf":
        fileType = "pdf";
        break;
      default:
        fileType = "xlsx";
    }

    downloadFileService.downloadTableDataByFormat(fileType, sheetType).then((res) => {
      let basename = getFileBaseName(res.file);
      handleDownload(basename, res.file);
    });
  };

  return (
    <div className="download-data-sheet">
        <div className="download-data-sheet-wrapper btn-group">
          <span>Eksportuj:</span>
        {buttonsType
          ? buttonsType.map((el, index) => {
              return (
                  <a className='btn btn-sm btn-white' data-button-type={el.toLowerCase()} download
                     key={`action-button-${index}`} onClick={handleButton}>
                    {el}
                  </a>
              );
            })
            : null}
      </div>
    </div>
  );
};

export default DownloadDataSheet;
