import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Collapse from "../mainelements/Collapse";
import logo from "../../../../../assets/w realizacji - nowe 2-Image.png";
import { TableDataContext } from "../../../../Router";
import checkPermissions from "../../../../../functions/checkPermissions";
import notificationServices from "../../../../services/notificationServices";
import PERMISSIONS_ENUMS from "../../../../../globalEnums/PERMISSIONS_ENUMS";
import { ReloadTableContext } from "../../../../Router";
import TABLE_TYPES from "../../../../../globalEnums/TABLE_TYPES_ENUMS";
import notificationBell from "../../../../../cetaMethods/notificationBell";
import usePopup from "../../../../helper/usePopup";
import IconTooltip from "./IconTooltip";
import Assignee from "../../Popup/AddOrder/Assignee";

const TableBodyRows = ({ rowData, user, celData }) => {
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const collapseRef = useRef([]);
  const rowWraperRef = useRef();
  const [allowAutoCollapse, setAllowAutoCollapse] = useState(true);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [getCollapseData, setGetCollapseData] = useState({
    get: false,
    order: null,
  });
  const [assignedPopup, setAssignePopup] = useState({ showPopup: false, order_id: rowData.id });

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleCollapse = (e) => {
    let clickedElement = e.currentTarget;
    let wrapper = clickedElement.closest(".row-wrapper");
    let id = wrapper.dataset.elementid;

    if(id == searchParams.get('order')){
      openCollapse(id);
    }else{
      searchParams.set('order', id);
      setSearchParams(searchParams)
    }

  };

  const openCollapse = (id = null) =>{
    if(id == rowData.order_id){
      if(!collapseRef.current.classList.contains("open")){
        if (!collapseRef.current.classList.contains("animation")) collapseRef.current.classList.add("animation");
        collapseRef.current.classList.add("open");
        setGetCollapseData({ get: true, order: rowData.order_id });
      }else{
        collapseRef.current.classList.remove("open");
      }

    }
  }

  useEffect(()=>{
    openCollapse(searchParams.get('order'))
  }, [searchParams])

  useEffect(() => {
    if (collapseRef.current.classList.contains("animation")) collapseRef.current.classList.remove("animation");
    collapseRef.current.classList.remove("open");
    // setGetCollapseData({ get: false, order: null });
    handleAutoCollapse();
  }, [tableContext]);

  const handleAssigne = () => {
    setAssignePopup({ showPopup: true, order_id: rowData.id });
  };
  const { initPopup } = usePopup();
  useEffect(() => {
    if (assignedPopup.showPopup) {
      initPopup(<Assignee setRealoadComponent={setReloadComponent} setAssignePopup={setAssignePopup} orderID={rowData.order_id} changeStatus={true}/>);
    }
  }, [assignedPopup]);
  const handleReadIcon = (e) => {
    let notificationId = e.currentTarget.dataset.elementid;
    e.stopPropagation();
    notificationServices.markNofificationAsRead(notificationId);

    setReloadComponent(true);

    notificationBell.getNotificationNumber();
  };

  const checkIfGraphicIsAssigned = () =>{
    return !!rowData.assigned_to;
  }

  const generateWrapperClass = (orderID) => {
    checkIfGraphicIsAssigned();
    let classArr = "row-wrapper";
    //TOFIX: disallowedStatuses switch to allowed statuses
    let disallowedStatuses = ["accepted_acc", "finished", "renew"] //list of statuses that make ACC unable to accept project
    if (
      (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) && !disallowedStatuses.includes(rowData.order_status)) ||
      (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE]) && ["accepted_acc", "restored"].includes(rowData.order_status)) ||
      (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) && !checkIfGraphicIsAssigned())
    ) {
      classArr += " user_can_accept";
    } else {
      classArr += " user_can_not_accept ";
    }

    return classArr;
  };

  const generateTdClass = (data, index) => {
    let result = `td `;
    if (tableContext.tableType == TABLE_TYPES.NOTIFICATION && index == celData.length - 1) result += `statsu-col `;
    if (data?.is_read) result += `read `;
    if(index === 0) result += `action-name`
    return result;
  };

  const handleNavigateToOrder = (e, archive) => {
    let orderID = e.currentTarget.dataset.elementid;
    if(archive){
      navigate(`/archive?order=${orderID}`);
      window.location.reload();
    }else if (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])) {
        navigate(`/orderdetail?order=${orderID}`);
      window.location.reload();
    } else {
      navigate(`/team?order=${orderID}`);
      window.location.reload();
    }
  };

  const handleAutoCollapse = () => {
    if (allowAutoCollapse) {
      let paramOrderID = searchParams.get("order");
      if (paramOrderID && parseInt(paramOrderID) == rowData.order_id) {
        setGetCollapseData({ get: true, order: paramOrderID });
        collapseRef.current.classList.add("open");
        setAllowAutoCollapse(false);
      }
    }
  };

  const displayValueHelper = (value) => {
    if(value){
      if(typeof value === 'object'){
        if(value.content){
          if(value.calculated_content && value.calculated_content[0]?.default_name) return value.calculated_content[0]?.default_name
          if(value.custom_input_value) return value.custom_input_value
          if(value.content) return value.content
        }
      }else{
        return value
      }
    }

    return ""
  }

  const setAdditionalRowClassName = (el) =>{
    let result = el.defined_field === 'action_name' && rowData.order_status=== 'cancelled' ? "action-name cancelled" : el.defined_field === 'action_name' ? 'action-name' : rowData.order_status === 'cancelled' ? 'cancelled' : '';

    if(rowData.is_favourite) result += ' pin'

    return result
  }
  const [position, setPosition] = useState(null);

  const showTooltip = (event) => {
    const rect = event.target.getBoundingClientRect();
    setPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  return (
      <>
        <div
          className={generateWrapperClass(rowData.order_id)}
          data-notificationid={rowData.order_id}
          key={`table-row-${rowData.data_index}`}
          data-elementid={rowData.order_id}
          ref={rowWraperRef}
        >
          <div className="row main-row">
            {celData.length
              ? celData.map((el, index) => {
                  return (
                    <div
                      className={generateTdClass(rowData, index)}
                      key={`key-${index}-${el}`}
                      data-index={rowData.data_index}
                      data-archive={el.value == "Zlecenie zakończone"}
                      onClick={
                        tableContext.tableType == TABLE_TYPES.NOTIFICATION && el.defined_field == 'action_name'
                          ? (e) => handleNavigateToOrder(e, el.action == "Zlecenie zakończone")
                          : handleCollapse
                      }
                      data-elementid={rowData.order_id}
                      style={{ order: el.position, width: `${el.width}%` }}
                    >
                      <div className="icons">
                        {rowData.is_observed && el.defined_field == 'action_name' ? <IconTooltip iconClassName={rowData.is_read ? "observed" : "observed"} text={'Obserwowane'} /> : null}
                        {rowData.is_reformat && el.defined_field == 'action_name' ? <IconTooltip iconClassName="reformat-project" text={'Formaty'} /> : null}
                        {rowData.is_duplicated && !rowData.is_counter && el.defined_field == 'action_name' ? <IconTooltip iconClassName="duplicated-project" text={'Powielone'} /> : null}
                        {rowData.is_counter && el.defined_field == 'action_name' ? <IconTooltip iconClassName="is_counter" text={'Licznik'}/> : null}
                        {rowData.is_restored && el.defined_field == 'action_name' ? <IconTooltip iconClassName="is_restored" text={'Przywrócone'} />: null}
                        {tableContext.tableType == TABLE_TYPES.IN_PROGRESS &&
                        el.defined_field == 'client_managed_by' &&
                        checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.IN_PROGRESS_ASSIGNE_PROJECT_CLIENT) ? (
                          <div className="assigne-project" onClick={handleAssigne}></div>
                        ) : null}
                        {tableContext.tableType == TABLE_TYPES.IN_PROGRESS &&
                        el.defined_field == 'managed_by' &&
                        checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.IN_PROGRESS_ASSIGNE_PROJECT_ACC) ? (
                                <IconTooltip iconClassName="assigne-project" text={'Opiekun'} handleClick={handleAssigne}/>
                        ) : null}
                        {tableContext.tableType == TABLE_TYPES.NOTIFICATION && rowData.is_read !== null && index == celData.length - 1 ? (
                          <div
                            className={rowData.is_read ? "mark-as-read read" : "mark-as-read unread"}
                            onClick={handleReadIcon}
                            data-elementid={rowData.order_id}
                          ></div>
                        ) : null}
                      </div>
                      <span className={setAdditionalRowClassName(el)} dangerouslySetInnerHTML={{ __html: displayValueHelper(el.value) }}></span>
                    </div>
                  );
                })
              : null}
          </div>
          <Collapse
            reformatSizes={rowData.reformat_sizes}
            orderReformat={rowData.order_reformat}
            rowWraperRef={rowWraperRef}
            collapseRef={collapseRef}
            index={rowData.data_index}
            logo={logo}
            orderID={rowData.order_id}
            getCollapseData={getCollapseData}
            rowData={rowData}
            celData={celData}
          />
        </div>
      </>
  );
};

export default TableBodyRows;
