import React, { useContext, useState, useEffect } from "react";
import { TableDataContext } from "../../../../Router";

import getBoxFields from "../../../../services/getBoxFields";
import PageComponent from "../../Team/globalelements/PageComponent";
import CollapseRow from "../collapseElements/CollapseRow";
import CorrectionList from "../collapseElements/CorrectionList";
import notificationServices from "../../../../services/notificationServices";
import variantsServices from "../../../../services/variantsServices";
import usePopup from "../../../../helper/usePopup";
import ErrorLoadingData from "../../Popup/AddOrder/ErrorLoadingData";

const Collapse = ({ collapseRef, orderID, getCollapseData, rowWraperRef, reformatSizes, rowData, orderReformat, celData }) => {
  const { tableContext, paginationPage } = useContext(TableDataContext);
  const [page, setPage] = useState(0);
  const [variantID, setVariantID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collapseData, setCollapseData] = useState(null);
  const [variantsData, setVariantsData] = useState([]);
  const [updateVariantData, setUpdateVariantsData] = useState(false);
  const [orderImages, setOrderImages] = useState([])
  const { initPopup } = usePopup();
  const findVariantsData = () => {
    variantsServices
      .getVariants(orderID)
      .then((r) => {
        setVariantsData(r.filter( variant => variant.is_saved));
        if (r && r[0]?.id) {
          setVariantID(r[0].id);
        }
      })
      .catch((error) => {
        console.error(error);
        initPopup(<ErrorLoadingData type={'negative'} info={'data_error'}/>)
      });
  };

  useEffect(() => {
    if (getCollapseData.get) findVariantsData();
  }, [updateVariantData, getCollapseData, orderID]);

  useEffect(() => {
    initCollapseData();
  }, [getCollapseData, orderID]);

  const initCollapseData = () => {
    if (getCollapseData.get) {
      setLoading(true);
      if (tableContext.tableType == "notification") {
        notificationServices
          .getNotificationHistory(orderID)
          .then((res) => {
            setLoading(false);
            setCollapseData(res);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'data_error'}/>)
          });
      } else {
        getBoxFields
          .getVariantBoxFieldValue(orderID)
          .then((res) => {
            setLoading(false);
            setCollapseData(res);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'data_error'}/>)
          });
      }
    }
  };

  if (loading) {
    return (
      <div className="collapse" ref={collapseRef}>
        <div className="row">
          <p className="loading-text">Loading</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="collapse" ref={collapseRef}>
        {tableContext.tableType == "notification" ? (
          <CollapseRow orderID={orderID} data={collapseData} showVariant={page} celData={celData}/>
        ) : (
          <PageComponent page={page} orderID={orderID} setPage={setPage} data={variantsData} setVariantID={setVariantID} showContent={true}>
            <CollapseRow
              variantID={variantID}
              orderReformat={orderReformat}
              reformatSizes={reformatSizes}
              rowWraperRef={rowWraperRef}
              orderID={orderID}
              data={collapseData}
              showVariant={page}
              rowData={rowData}
              variantsData={variantsData}
              updateVariantData={updateVariantData}
              setUpdateVariantsData={setUpdateVariantsData}
              />
            {tableContext.tableType == "in_progress" && <CorrectionList variantID={variantID} showVariant={page} data={collapseData} orderID={orderID} rowData={rowData} page={page} paginationPage={paginationPage}/>}
          </PageComponent>
        )}
      </div>
    </>
  );
};

export default Collapse;
