import React, { useEffect, useState } from "react";
import Row from "../globalelements/Row";
import AssignedProjectBox from "../globalelements/AssignedProjectBox";
import FilterBar from "../../../globalElements/Filters/FilterBar";
import { Droppable, Draggable } from "react-beautiful-dnd";
import SearchBar from "../../../globalElements/SearchBar";
import orderServices from "../../../../services/orderServices";
import { ENUMS } from "../helper/segregateOrdersColumns";
import FILTER_BAR_TYPES from "../../../globalElements/Filters/ENUMS/FILTER_BAR_TYPE";
import Loader from "../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../globalElements/Loader/LOADER_TYPE";
import AssigneePopup from "../../Popup/AssigneePopup";

const MyProjectsBox = ({
  data,
  setIsDroppableToTeam,
  columns,
  setColumns,
  permission,
  setRealoadComponent,
  isDroppableToMyProjects,
  myProjectBoxLoading,
  setMyProjectBoxLoading,
  setOrderId,
  startProjectRef,
  projectAdditionalActionsRef,
  orderId,
}) => {
  const [assignedPopup, setAssignePopup] = useState({ showPopup: false, order_id: null });
  const [searchValue, setSearchValue] = useState("");
  const [externalEndoint, setExternalEndpoint] = useState("");

  const handleSerchBar = () => {
    setMyProjectBoxLoading(true);
    orderServices.getOrderAssignedToUserSordetBy(null, searchValue, externalEndoint).then((res) => {
      setColumns({
        ...columns,
        [ENUMS.COLUMNS.MyProjectsBox]: {
          ...[ENUMS.COLUMNS.MyProjectsBox],
          items: res,
        },
      });
      setMyProjectBoxLoading(false);
    });
  };

  useEffect(() => {
    if (searchValue) {
      const timer = setTimeout(() => {
        handleSerchBar();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [searchValue, externalEndoint]);

  useEffect(() => {
    if (externalEndoint) {
      handleSerchBar();
    }
  }, [externalEndoint]);

  return (
    <div className={permission == "CLIENT" ? "my-project-box-container box box-client" : "my-project-box-container box"}>
      <Row title rowBorder>
        <h1 className={"box-title"}>{permission == "CLIENT" ? "moje projekty" : "moje projekty - aktywne"}</h1>
        <FilterBar
          showPerAccount={false}
          showStatus={true}
          showDeadline={true}
          filterType={FILTER_BAR_TYPES.MY_PROJECT_BOX}
          setDataLoader={setMyProjectBoxLoading}
          setExternalEndpoint={setExternalEndpoint}
        />
        <SearchBar setSearchValue={setSearchValue} searchValue={searchValue} />
      </Row>
      <Droppable droppableId={"MyProjectsBox"} key={"2"} isDropDisabled={!isDroppableToMyProjects}>
        {(provided, snapshot) => {
          return (
            <div
              className="boxes-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={data?.length > 0 ? { paddingBottom: "300px" } : { height: "100%" }}
            >
              {data?.length > 0 &&
                data.map((element, index) => {
                  return (
                    <Draggable key={`myproject-${element.id}`} draggableId={`myproject-${element.id}`} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              ...provided.draggableProps.style,
                            }}
                            onMouseDown={() => setIsDroppableToTeam(true)}
                          >
                            <AssignedProjectBox
                              setRealoadComponent={setRealoadComponent}
                              setAssignePopup={setAssignePopup}
                              data={element}
                              id={element.id}
                              key={`myproject-${element.id}`}
                              my_project
                              permission={permission}
                              setOrderId={setOrderId}
                              startProjectRef={startProjectRef}
                              projectAdditionalActionsRef={projectAdditionalActionsRef}
                              orderId={orderId}
                              setLoading={setMyProjectBoxLoading}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}

              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      {myProjectBoxLoading && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
    </div>
  );
};

export default MyProjectsBox;
