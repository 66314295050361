import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ saveData, initialValue, placeholder, readOnly, style }) => {
  const [editorContent, setEditorContent] = useState('');

  const removeWhitespaceInPTags = (input = "") => {
    //remove white space at the beginning and the end of a content between <p></p> html tags
    const regex = /<p>\s*(.*?)\s*<\/p>/g;
    const result = input.replace(regex, (match, p1) => {
      return `<p>${p1}</p>`;
    });

    return result;
  };

  const saveTextEditorData = (html) => {
    //saving global data

    const trimmedHtml = removeWhitespaceInPTags(html);

    saveData(trimmedHtml);
  };

  const handleChange = (html) => {
    setEditorContent(html);
    saveTextEditorData(html);
  };


  useEffect(()=>{
    setEditorContent(initialValue)
  }, [initialValue])
  
  const [isFocused, setIsFocused] = useState(false);
  const handleReturnStyles = () => {
    // Create a new object with the existing styles
    let temp = { ...style };

    // Conditionally modify the border property
    if (isFocused) {
      temp.border = '1px solid #1ab394';
    }

    return temp;
  };
  return (
    <ReactQuill
      theme={"bubble"}
      value={editorContent}
      onChange={handleChange}
      style={handleReturnStyles()}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      placeholder={placeholder ? placeholder : "kliknij, aby wprowadzić dodatkowe informacje..."}
      readOnly={readOnly}
      modules={{
        toolbar: [["bold"], ["image"], ["clean"], [{ color: ['#eb008c', '#00000' ] }]]
      }}
    />
  );
};

export default TextEditor;
