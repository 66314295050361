import React from 'react';
import ReactDOM from "react-dom";

const Tooltip = ({text, position}) => {
    const returnWidth = () => {
        switch(text){
            case "Obserwujesz to zlecenie":
                return 155
            case "Reformat":
                return 65
            case "Duplikat":
                return 60
            case "Dodano licznik":
                return 95
            case "Przywrócone zlecenie":
                return 140
        }
    }
    return (
        ReactDOM.createPortal(
            <div
                style={{
                    position: 'absolute',
                    top: position.top - 40,
                    left: position.left - (returnWidth() / 2),
                    width: 'auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    fontSize: 13,
                    borderRadius: '5px',
                    color: 'white',
                    padding: '5px 10px',
                    fontFamily: 'Open Sans',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
                className='observe-tooltip'
            >
                {text}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: 0,
                        height: 0,
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        borderTop: '10px solid rgba(0, 0, 0, 0.75)',
                    }}
                />
            </div>,
            document.body
        )
    );
}

export default Tooltip;
