import React from 'react';
import {ReactComponent as Info}  from "../../../../../assets/popups/info.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import 'react-toastify/dist/ReactToastify.css';
const Language = () => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    return (
        <div className='send-popup'>
            <div className="upper-part-popup">
                <Info />
                <h3>Wybierz język zlecenia <br/> przed kontynuacją.</h3>
            </div>
            <div className='single-button'>
                <button className="button accept" onClick={closeOrderPopup}>OK</button>
            </div>
        </div>
    );
}

export default Language;
