import React from 'react';
import {ReactComponent as Delete}  from "../../../../../assets/popups/delete.svg"
import {ReactComponent as Warning}  from "../../../../../assets/popups/warning.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const Abort = ({edit}) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    return (
        <div className='abort-popup'>
            {edit ? <Warning/> : <Delete />}
            {edit ? <h3>Czy na pewno chcesz<br/> nieodwracalnie usunąć <br/> wprowadzone zmiany?</h3> : <h3>Czy na pewno chcesz nieodwracalnie usunąć rozpoczęte zlecenie?</h3>}
            <p>Tej operacji nie można cofnąć.</p>
            <div className='buttons'>
                <button className="button cancel" onClick={()=> closeOrderPopup()}>Anuluj</button>
                <button className="button abort" onClick={()=>{
                    closeOrderPopup();
                }}>Usuń</button>
            </div>
        </div>
    );
}

export default Abort;
