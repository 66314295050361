import React, { useState } from "react";
import usePopup from "../../../helper/usePopup";
import { ReactComponent as Icon } from "../../../../assets/bg-src-icon.svg";

const BackgroundSrcInputPopup = ({ saveData }) => {
  const [inputValue, setInputValue] = useState("");
  const { closePopup } = usePopup();

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveData = () =>{
    if(inputValue){
      saveData(inputValue)
      closePopup()
    } 
  }

  return (
    <div className="background-src-popup">
      <div className="title-section">
        <div className="icon">
          <Icon />
        </div>
        <h3>
        Dodaj link z grafiką<br />do zlecenia 
        </h3>
      </div>
      <div className="index">
        <input className="input-field" type="text" value={inputValue} onChange={handleInput} placeholder="Wpisz. wklej link" />
      </div>
      <div className="bottom">
        <button className="cancel" onClick={() => closePopup()}>
          Anuluj
        </button>
        <button className="save" onClick={handleSaveData}>
          Wyślij
        </button>
      </div>
    </div>
  );
};

export default BackgroundSrcInputPopup;
