import React from "react";

const Weekdays = ({ currentDays }) => {
  return (
    <div className="calendar">
      <ul className="weeks" key={"ul-1"}>
        <li key={"li-0"}>Pon</li>
        <li key={"li-1"}>Wt</li>
        <li key={"li-2"}>Śr</li>
        <li key={"li-3"}>Czw</li>
        <li key={"li-4"}>Pt</li>
        <li key={"li-5"}>
          Sob
        </li>
        <li key={"li-6"}>
          Niedz
        </li>
      </ul>
      <ul className="days" key={"ul-2"}>
        {currentDays}
      </ul>
    </div>
  );
};

export default Weekdays;
