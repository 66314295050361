import React from "react";
import FILTER_BAR_DROPDOWN_TYPES from "../ENUMS/FILTER_BAR_DROPDOWN_TYPES";
import Dropdown from "../../Dropdown/Dropdown";
import CalenderFromTo from "../../CalenderFromTo";

const FilterTabs = ({
                        showPerAccount,
                        showStatus,
                        showDeadline,
                        handleTab,
                        tabsRef,
                        isPerAccountChosen,
                        isStatusChosen,
                        isDedlineChosen,

                        showDropdown,
                        filterData,
                        dropdownType,
                        filterRef,
                        handleDropdownListElements,
                        handleCheckBox,
                        setShowDropdown,
                        dropdownRef,
                        chosenDateFrom,
                        setChosenDateFrom,
                        chosenDateTo,
                        setChosenDateTo,
                        getFilteredData,
                        calenderRef
                    }) => {

    const handleFilterLabelStatus = () => {
        return isPerAccountChosen || isStatusChosen || isDedlineChosen
    }

    return (
        <>
            <span id="label">Filtruj:</span>
            {showPerAccount && (
                <div
                    className={`btn btn-white dropdown-toggle`}
                    data-tab-type={FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT}
                    onClick={handleTab}
                    ref={(el) => tabsRef.current.push(el)}
                >
                    <span {...(isPerAccountChosen && {style: {color: `#333333`, fontWeight: 700}})}>User</span>
                    {showDropdown && (
                        <div className={`dropdown dropdown-checkbox`} ref={dropdownRef}>
                            {[FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT].includes(dropdownType) && (
                                <Dropdown
                                    data={filterData[dropdownType]}
                                    dropdownType={dropdownType}
                                    handleListElement={handleDropdownListElements}
                                    displayCheckbox={true}
                                    handleCheckBox={handleCheckBox}
                                    positionToElementRef={filterRef}
                                    setShowDropdown={setShowDropdown}
                                >
                                </Dropdown>
                            )}
                        </div>
                    )}
                </div>
            )}
            {showStatus && (
                <div className={`btn btn-white dropdown-toggle`} data-tab-type={FILTER_BAR_DROPDOWN_TYPES.STATUS}
                     onClick={handleTab}
                     ref={(el) => tabsRef.current.push(el)}>
                    <span {...(isStatusChosen && {style: {color: `#333333`, fontWeight: 700}})}>Typ/Type</span>
                    {showDropdown && (
                        <div className={`dropdown dropdown-checkbox`} ref={dropdownRef}>
                            {[FILTER_BAR_DROPDOWN_TYPES.STATUS].includes(dropdownType) && (
                                <Dropdown
                                    data={filterData[dropdownType]}
                                    dropdownType={dropdownType}
                                    handleListElement={handleDropdownListElements}
                                    displayCheckbox={true}
                                    handleCheckBox={handleCheckBox}
                                    positionToElementRef={filterRef}
                                    setShowDropdown={setShowDropdown}

                                >
                                </Dropdown>
                            )}
                        </div>
                    )}
                </div>
            )}
            {showDeadline && (
                <div className={`btn btn-white dropdown-toggle`} data-tab-type={FILTER_BAR_DROPDOWN_TYPES.DEADLINE}
                     onClick={handleTab}
                     ref={(el) => tabsRef.current.push(el)}>
                    <span {...(isDedlineChosen && {style: {color: `#333333`, fontWeight: 700}})}>Deadline</span>
                    {showDropdown && (
                        <div className={`dropdown dropdown-checkbox`} ref={dropdownRef}>
                            {![FILTER_BAR_DROPDOWN_TYPES.STATUS, FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT].includes(dropdownType) && (
                            <CalenderFromTo
                                chosenDateFrom={chosenDateFrom}
                                setChosenDateFrom={setChosenDateFrom}
                                chosenDateTo={chosenDateTo}
                                setChosenDateTo={setChosenDateTo}
                                handleFilter={getFilteredData}
                                calenderRef={calenderRef}
                                setShowDropdown={setShowDropdown}
                            />
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default FilterTabs;
